import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import {
  SAVE_ANALYTICS_QUERY,
  FETCH_EXISTING_QUERY,
  SET_QUERY_LIST,
  SET_QUERY_LIST_LOADING,
  FETCH_QUERY_BASED_ON_ID,
  SET_QUERY_IS_CUSTOM,
  SET_QUERY_TITLE,
  SET_QUERY_CODE,
  UPDATE_ANALYTICS_QUERY,
  SET_QUERY_PROCESSING,
  SET_QUERY_FORM_LOADING,
  SET_SELECTED_QUERY_HUB,
  SET_QUERY_TABLE_NAME,
  SET_QUERY_DB,
  SET_TABLE_COLUMNS,
  SET_UPDATE_REQUIRED,
  SET_ANALYTICS_QUERY_ACTIVE,
  FETCH_EXISTING_ASSOCIATED_CHART,
  SET_CHART_LIST,
  FETCH_REPORT_TABLES,
  SET_REPORT_TABLE,
  FETCH_ASSOCIATED_CHART_DATA,
  SET_ASSOCIATED_REPORT_ID,
  SET_ASSOCIATED_CHART_TYPE,
  SET_ASSOCIATED_CHART_QUERY,
  SET_ASSOCIATED_CHART_TAG,
  UPDATE_ASSOCIATED_CHART,
  SAVE_ASSOCIATED_CHART,
  SET_ASSOCIATED_CHART_IDENTIFIER,
  SET_RELATED_REPORT_ID,
  SET_INDEX_CODE,
  SET_CHANGE_MESSAGE,
  queryList,
  chartList,
} from '../actions/analyticsQuery';
import { POPUP_ALERT } from '../actions/alert';
import {
  saveAnalyticsQueryApi,
  fetchAnalyticsQueryApi,
  fetchQueryBasedOnIdApi,
  updateAnalyticsQueryApi,
  setActiveQueryApi,
  fetchAssociatedChartApi,
  fetchReportTablesApi,
  fetchAssociatedChartDataApi,
  updateAssociatedChartApi,
  saveAssociatedChartApi,
} from '../api/analyticsQuery';
import moment from 'moment';
import { isNull } from 'lodash';
import history from '../history';

export default [
  takeLatest(SAVE_ANALYTICS_QUERY, savaAnalyticsQuerySaga),
  takeLatest(FETCH_EXISTING_QUERY, fetchExistingQuerySaga),
  takeLatest(FETCH_QUERY_BASED_ON_ID, fetchQueryBasedOnIdSaga),
  takeLatest(UPDATE_ANALYTICS_QUERY, updateAnalyticsQuerySaga),
  takeLatest(SET_ANALYTICS_QUERY_ACTIVE, setAnalyticsQueryActiveSaga),
  takeLatest(FETCH_EXISTING_ASSOCIATED_CHART, fetchExistingAssociatedChartSaga),
  takeLatest(FETCH_REPORT_TABLES, fetchReportTableSaga),
  takeLatest(FETCH_ASSOCIATED_CHART_DATA, fetchAssociatedChartDataSaga),
  takeLatest(UPDATE_ASSOCIATED_CHART, updateAssociatedChartSaga),
  takeLatest(SAVE_ASSOCIATED_CHART, saveAssociatedChartSaga),
];

const manipulateData = data => {
  return data.map(item => {
    return {
      ...item,
      createdAt: moment(item.createdAt).format('Do MMM YYYY'),
      updatedAt: moment(item.updatedAt).format('Do MMM YYYY'),
    };
  });
};

function* savaAnalyticsQuerySaga({ data }) {
  try {
    const list = yield select(queryList);
    yield put({
      type: SET_QUERY_PROCESSING,
      data: true,
    });
    const { data: resData } = yield call(saveAnalyticsQueryApi, data);
    if (resData && !resData.errorMessage) {
      history.push(`/build-analytics-chart/edit/${resData.id}`);
      yield put({ type: SET_TABLE_COLUMNS, data: resData.columns });
      yield put({ type: SET_UPDATE_REQUIRED, data: resData.updateRequired });
      yield put({
        type: SET_QUERY_LIST,
        data: list.concat(manipulateData([resData])),
      });
    } else if (resData && resData.errorMessage) {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: resData.errorMessage },
      });
    }
    yield put({
      type: SET_QUERY_PROCESSING,
      data: false,
    });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Saving Query' },
    });
  }
}

function* saveAssociatedChartSaga({ data }) {
  try {
    const list = yield select(chartList);
    yield put({
      type: SET_QUERY_PROCESSING,
      data: true,
    });
    const { data: resData } = yield call(saveAssociatedChartApi, data);
    history.push(`/associate-charts/edit/${resData.id}`);
    yield put({
      type: SET_CHART_LIST,
      data: list.concat(manipulateData([resData])),
    });
    yield put({
      type: SET_QUERY_PROCESSING,
      data: false,
    });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Saving Query' },
    });
  }
}

function* fetchExistingQuerySaga() {
  try {
    yield put({
      type: SET_QUERY_LIST_LOADING,
      data: true,
    });
    const { data } = yield call(fetchAnalyticsQueryApi);
    yield put({
      type: SET_QUERY_LIST,
      data: manipulateData(data),
    });
    yield put({
      type: SET_QUERY_LIST_LOADING,
      data: false,
    });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching existing query' },
    });
  }
}

function* fetchQueryBasedOnIdSaga({ data }) {
  try {
    yield put({
      type: SET_QUERY_FORM_LOADING,
      data: true,
    });
    const { data: resData } = yield call(fetchQueryBasedOnIdApi, data);
    if (resData) {
      yield all([
        put({ type: SET_QUERY_IS_CUSTOM, data: resData.isCustom }),
        put({ type: SET_QUERY_TITLE, data: resData.title }),
        put({ type: SET_QUERY_CODE, data: resData.query }),
        put({ type: SET_SELECTED_QUERY_HUB, data: resData.hub }),
        put({ type: SET_QUERY_TABLE_NAME, data: resData.tableName }),
        put({ type: SET_QUERY_DB, data: resData.queryDb }),
        put({ type: SET_TABLE_COLUMNS, data: resData.columns }),
        put({ type: SET_UPDATE_REQUIRED, data: resData.updateRequired }),
        put({ type: SET_INDEX_CODE, data: resData.index }),
      ]);
    }
    yield put({
      type: SET_QUERY_FORM_LOADING,
      data: false,
    });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching query' },
    });
  }
}

function* updateAnalyticsQuerySaga({ data }) {
  try {
    yield put({
      type: SET_QUERY_PROCESSING,
      data: true,
    });
    const { data: resData } = yield call(updateAnalyticsQueryApi, data);
    if (resData && !resData.errorMessage) {
      // yield put({ type: FETCH_EXISTING_QUERY });
      yield put({ type: SET_TABLE_COLUMNS, data: resData.columns });
      yield put({ type: SET_UPDATE_REQUIRED, data: resData.updateRequired });
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Chart query Updated!' },
      });
    } else if (resData && resData.errorMessage) {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: resData.errorMessage },
      });
    }
    yield put({
      type: SET_QUERY_PROCESSING,
      data: false,
    });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error updating query' },
    });
  }
}

function* setAnalyticsQueryActiveSaga({ data }) {
  try {
    const list = yield select(queryList);
    const { data: resData } = yield call(setActiveQueryApi, data);
    if (resData && resData.length) {
      const updatedObj = resData[0];
      const updatedList = list.map(item => {
        if (item.id === updatedObj.id) {
          return {
            ...updatedObj,
            createdAt: moment(updatedObj.createdAt).format('Do MMM YYYY'),
            updatedAt: moment(updatedObj.updatedAt).format('Do MMM YYYY'),
          };
        }
        return item;
      });
      yield put({
        type: SET_QUERY_LIST,
        data: updatedList,
      });
    }
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error updating query' },
    });
  }
}

function* fetchExistingAssociatedChartSaga() {
  try {
    yield put({
      type: SET_QUERY_LIST_LOADING,
      data: true,
    });
    const { data } = yield call(fetchAssociatedChartApi);
    yield put({
      type: SET_CHART_LIST,
      data: manipulateData(data),
    });
    yield put({
      type: SET_QUERY_LIST_LOADING,
      data: false,
    });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching associated chart' },
    });
  }
}

function* fetchReportTableSaga() {
  try {
    const { data } = yield call(fetchReportTablesApi);
    yield put({
      type: SET_REPORT_TABLE,
      data,
    });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching report tables' },
    });
  }
}

function* fetchAssociatedChartDataSaga({ data }) {
  try {
    yield put({
      type: SET_QUERY_FORM_LOADING,
      data: true,
    });
    const { data: resData } = yield call(fetchAssociatedChartDataApi, data);
    if (resData) {
      yield all([
        put({ type: SET_ASSOCIATED_REPORT_ID, data: resData.reportId }),
        put({ type: SET_ASSOCIATED_CHART_TYPE, data: resData.chartType }),
        put({
          type: SET_ASSOCIATED_CHART_QUERY,
          data: isNull(resData.chartQuery) ? '' : resData.chartQuery,
        }),
        put({ type: SET_ASSOCIATED_CHART_TAG, data: resData.chart }),
        put({
          type: SET_ASSOCIATED_CHART_IDENTIFIER,
          data: resData.chartIdentifier,
        }),
        put({
          type: SET_RELATED_REPORT_ID,
          data: isNull(resData.relatedReportId) ? '' : resData.relatedReportId,
        }),
        put({
          type: SET_CHANGE_MESSAGE,
          data: isNull(resData.changeMessage) ? '' : resData.changeMessage
        })
      ]);
    }
    yield put({
      type: SET_QUERY_FORM_LOADING,
      data: false,
    });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching associated chart' },
    });
  }
}

function* updateAssociatedChartSaga({ data }) {
  try {
    yield put({
      type: SET_QUERY_PROCESSING,
      data: true,
    });
    const { data: resData } = yield call(updateAssociatedChartApi, data);
    if (resData) {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Associated Chart Updated!' },
      });
    }
    yield put({
      type: SET_QUERY_PROCESSING,
      data: false,
    });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error updating associated chart' },
    });
  }
}
