import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { map, find } from 'lodash';
import { Col, Divider, PageHeader, Row, Button, Icon, Select } from 'antd';

import {
  callMLServiceApiAction,
  setMLServiceApiResponseAction,
} from './actions';
import { MLServices } from './helpers/utils';
import APIPayload from './components/APIPayload';
import APIResponse from './components/APIResponse';
import { notifyError } from '../../utils/notify';

import './index.scss';

const { Option } = Select;
const DefaultPayload = { body: {} };

const DefaultBody = {
  'users-weighted-search': {
    body: {
      body: JSON.stringify({
        expertise:
          'Music, Entertainment & Recreation, Media & Marketing, Sales and Marketing',
        companies: '',
        job_titles:
          'Music Industry Analyst, Marketing Specialist at Record Label, Artist Relations Manager, Music Industry Consultant',
        headline:
          'Helping individuals break into the music industry and align their career with their passion for music',
        majors: 'Economics, Music Business, Marketing, Communications',
        industries:
          'AgriTech, Urban Farming, Agriculture, Software Development',
        help_topics:
          'Industry insights, Networking / Informational Interviewing / Introductions to others in my field, Career Exploration and Planning, Application and Interviewing, Personal branding and networking',
        weights_dict: {
          expertise: 0.15,
          companies: 0,
          job_titles: 0.3,
          headline: 0.3,
          majors: 0.15,
          help_topics: 0.15,
        },
        hub_id: 3,
        standard_user_types: ['alumni'],
        user_types: ['alumni'],
        excluded_ids: [],
        number_recommendations: 5,
      }),
    },
  },
  'users-cp-weighted-search': {
    body: {
      body: JSON.stringify({
        title: 'Project Managers',
        hub_id: 447,
        standard_user_type: 'alumni',
        number_recommendations: 10,
        weights_dict: {
          expertise: 0.15,
          companies: 0.2,
          title: 0.3,
          headline: 0.3,
          education: 0.15,
          help_topics: 0.15,
        },
        expertise: '',
        help_topics: '',
        excluded_ids: [],
      }),
    },
  },
  'jobs-recommendations-es': {
    body: {
      body: JSON.stringify({
        query_id: 3848328,
        hub_id: 3,
        recency_weight: 0.5,
        recommendation_type: 'job',
        number_recommendations: 10,
        filters: {
          company_title: [],
          location_coordinates: [],
          job_industry_id: [],
          seniority: [],
        },
      }),
    },
  },
  'jobs-search-es': {
    body: {
      body: JSON.stringify({
        query: 'analytics',
        hub_id: 3,
        recency_weight: 0.5,
        number_recommendations: 10,
        filters: {
          company_title: [],
          location_coordinates: [],
          job_industry_id: [],
          seniority: [],
        },
      }),
    },
  },
};

const MLServiceApi = ({
  callMLServiceApi,
  mlServiceApi,
  setMLServiceApiResponse,
}) => {
  const [currentMLService, setCurrentMLService] = useState();
  const [currentApi, setCurrentApi] = useState();
  const [payload, setPayload] = useState(DefaultPayload);

  const selectMLService = value => {
    if (currentMLService !== value) {
      setCurrentMLService(value);
      setCurrentApi();
      setPayload(DefaultPayload);
      setMLServiceApiResponse({ loading: false, response: null });
    }
  };
  const selectApi = value => {
    if (currentApi !== value) {
      setCurrentApi(value);
      setPayload(DefaultBody[value] || DefaultPayload);
    }
  };
  const handleClearPayload = () => {
    setPayload(DefaultPayload);
  };

  const setPayloadBody = newBodyParam => {
    const transformedData = {};
    if (
      currentMLService === 'recommendation' &&
      currentApi === 'recommendations' &&
      newBodyParam?.query_id
    ) {
      if (Number(newBodyParam?.query_id)) {
        transformedData.query_id = Number(newBodyParam?.query_id);
      }
    }
    if (newBodyParam.body) {
      setPayload({
        ...payload,
        body: {
          ...payload.body,
          ...newBodyParam,
        },
        newBodyParam,
      });
    } else {
      setPayload({
        ...payload,
        body: {
          ...payload.body,
          ...newBodyParam,
          ...transformedData,
        },
      });
    }
  };

  const handleSubmit = () => {
    let finalBody = payload.body;
    try {
      if (payload.newBodyParam) {
        const jsonBody = JSON.parse(payload.newBodyParam.body);
        finalBody = {
          ...finalBody,
          ...jsonBody,
        };
      }
      if (finalBody.body && typeof finalBody.body === 'string') {
        finalBody = JSON.parse(finalBody.body);
      }
    } catch (err) {
      notifyError(err, false, 'Error parsing body as json');
    }
    callMLServiceApi({
      service: currentMLService,
      api: currentApi,
      body: finalBody,
    });
  };

  const mlService = find(MLServices, { value: currentMLService });
  return (
    <>
      <PageHeader
        title="ML Service API"
        subTitle="Check ML Results against the various ML APIs"
      >
        <Divider />
        <Row
          type="flex"
          justify="space-around"
          gutter={8}
          className="ml-service-api"
        >
          <Col span={4}>
            <div>Select the ML Service</div>
            <Select
              placeholder="Select Service"
              onChange={d => selectMLService(d)}
              value={currentMLService}
              style={{ width: '100%' }}
            >
              {map(MLServices, source => (
                <Option key={source.value} value={source.value}>
                  {source.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <div>Select the API</div>
            <Select
              placeholder="Select Api"
              onChange={selectApi}
              value={currentApi}
              style={{ width: '100%' }}
              disabled={!mlService}
            >
              {map(mlService?.api, source => (
                <Option key={source.value} value={source.value}>
                  {source.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Divider />
        {/* <Row
          type="flex"
          justify="space-around"
          gutter={8}
          className="ml-service-api"
        >
        </Row> */}
        <APIPayload
          currentMLService={currentMLService}
          currentApi={currentApi}
          body={payload.body}
          setBody={setPayloadBody}
        />
        <Row
          type="flex"
          justify="center"
          gutter={10}
          style={{ marginTop: '10px' }}
        >
          <Col span={5}>
            <Button
              type="primary"
              onClick={() => handleSubmit()}
              style={{ width: '100%' }}
              loading={mlServiceApi.loading}
              disabled={!currentMLService || !currentApi}
            >
              <Icon type="filter" />
              Submit
            </Button>
          </Col>
          <Col span={4}>
            <Button
              disabled={mlServiceApi.loading}
              onClick={() => handleClearPayload()}
              style={{ width: '100%' }}
            >
              <Icon type="sync" />
              Clear All
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <div className="ml-service-api-content">
        <APIResponse
          currentMLService={currentMLService}
          currentApi={currentApi}
          response={mlServiceApi.response}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  callMLServiceApi: callMLServiceApiAction,
  setMLServiceApiResponse: setMLServiceApiResponseAction,
};

const mapStateToProps = ({ MLService: { mlServiceApi } }) => ({
  mlServiceApi,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MLServiceApi)
);
