import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Switch,
  Layout,
  Table,
  Popconfirm,
  Select,
  InputNumber,
  Tooltip,
  Icon,
  Checkbox,
  Radio,
} from 'antd';
import React, { Component, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Content from '../../../component/Content';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  handleGetCampaignInfo,
  updateCampaignInfo,
  handleCreateOrUpdateCampaignInfo,
  handleOpenEmailEditor,
  handleDeleteCampaignEmail,
  handleUpdateEmailTemplate,
  resetCampaignInfo,
} from '../actions';
import { fetchAdminTags } from '../../../actions';
import { debounce, get, isEmpty, isEqual, omit, orderBy, chain, flatten, filter } from 'lodash';
import Loader from '../../../component/Loader/Loader';
import { moduleOptions, subModuleOptions } from '../utils';
import { standardUserTypeOptions } from '../../../utils/helpers'
import notify from '../../../utils/notify';
import ConfirmCloseModal from '../EmailEditor/ConfirmCloseModal';
import { ConfirmDeleteModal } from './CampaignsList';
import { isNumber } from 'lodash';
import MultiSelect from '../../../component/MultiSelect';
import variations from '../../../constants/defaultSettingVariationConstants';

const { Footer } = Layout;
const { Option } = Select;

export const CampaignHeader = ({
  name,
  isActive,
  onActiveToggle,
  onInputChange,
}) => {
  return (
    <Card title="Campaign Name">
      <Row>
        <Col span={8}>
          <Input
            placeholder="Campaign Name(Required)"
            defaultValue={name}
            onChange={onInputChange}
            required={true}
          />
        </Col>
        <Col span={12} />
        <Col span={4}>
          <div className="campaigns_new--status">
            Is Active
            <Switch onClick={onActiveToggle} checked={isActive} />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

const EditColumnTitle = ({ name }) => (
  <Tooltip title={`Contents in this column are editable`}>
    <div className={''}>
      <span>{name}</span>
      <Icon type="edit" />
    </div>
  </Tooltip>
);

export const CampaignEmailsTable = ({
  loading = false,
  dataSource,
  onEdit,
  onDelete,
  onColumnChange,
  id,
  onAddNewTemplateClicked,
}) => {
  const columns = [
    {
      title: 'Email Template',
      dataIndex: 'name',
    },
    {
      title: <EditColumnTitle name="Subject Line" />,
      dataIndex: 'messageSubject',
      render: (value, record) => {
        return (
          <Input
            id={`subject_${record.id}`}
            value={value}
            onChange={e =>
              onColumnChange(record, { messageSubject: e.target.value }, true)
            }
            onPressEnter={e => {
              if (isEmpty(e.target.value)) {
                notify('Subject is required.', 'error');
              } else {
                onColumnChange(record, { messageSubject: e.target.value });
              }
            }}
          />
        );
      },
    },
    {
      title: <EditColumnTitle name="Delay (days)" />,
      dataIndex: 'delay',
      render: (value, record) => {
        return (
          <InputNumber
            id={`delay_${value}`}
            min={1}
            max={365}
            value={value}
            onChange={(value = 1) => {
              if (value === null) {
                onColumnChange(record, { delay: 1 });
              } else if (isNumber(value)) {
                onColumnChange(record, { delay: +value });
              }
            }}
          />
        );
      },
    },
    {
      title: <EditColumnTitle name="Standard User type" />,
      dataIndex: 'standardUserType',
      render: (value, record) => {
        return (
          <Select
            mode="multiple"
            id="standardUserType"
            placeholder="Select an user type"
            value={value}
            onChange={value => {
              if (value && value.length > 0) {
                onColumnChange(record, { standardUserType: value });
              } else {
                notify('Standardized user types is required.', 'error');
              }
            }}
            getPopupContainer={node => node.parentNode}
          >
            {standardUserTypeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: <EditColumnTitle name="Import vs Joined" />,
      dataIndex: 'importStatus',
      editable: true,
      render: (value, record) => {
        return (
          <Select
            id="importStatus"
            placeholder="Select an option"
            value={value}
            onChange={value => {
              onColumnChange(record, { importStatus: value });
            }}
            getPopupContainer={node => node.parentNode}
          >
            <Option value="joined">Only joined users</Option>
            <Option value="imported">Only imported users</Option>
            <Option value="joinedAndImported">Joined and imported users</Option>
          </Select>
        );
      },
    },
    {
      title: 'Actions',
      className: 'campaigns_new_cm__table__actions',
      render: (value, record) => {
        return (
          <div className="campaigns_new_cm__table__actions_block">
            <Button
              size="small"
              className="campaigns_new_cm__table__actions_edit"
              type="link"
              onClick={() => onEdit(value)}
            >
              Edit
            </Button>
            <Button
              size="small"
              className="campaigns_new_cm__table__actions_delete"
              type="link"
              onClick={() => onDelete(value)}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Card
      title="Campaign Emails"
      extra={
        <Button type="primary" onClick={onAddNewTemplateClicked}>
          Add New Email Template
        </Button>
      }
    >
      <Table
        className="campaigns_new_cm__table"
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        pagination={dataSource && dataSource.length > 10}
      />
    </Card>
  );
};

class CampaignInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModalPath: null,
      deleteEmail: null,
      adminTags: []
    };
    this.handleInputThrottled = debounce(this.handleColumnValueChanged, 10);
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      handleGetCampaignInfo,
      campaignInfo: { id: currentCampaignId },
      history,
    } = this.props;

    this.props.fetchAdminTags();

    if (+id !== currentCampaignId) {
      handleGetCampaignInfo(id);
    }
    window.addEventListener('beforeunload', this.onRedirect);
    this.unblock = history.block(this.onRedirect);
  }

  componentWillUnmount() {
    this.unblock && this.unblock();
  }

  componentDidUpdate(prevProps) {
    const { adminTags, adminTagsLoading } = this.props;

    if (
      prevProps.adminTagsLoading !== adminTagsLoading &&
      !adminTagsLoading &&
      adminTags.length
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        adminTags: adminTags.map(({ id, name }) => ({
          key: id,
          name,
        })),
      });
    }
  }

  onRedirect = e => {
    if (!this.isSaveDisabled()) {
      if (e.preventDefault) {
        e.preventDefault && e.preventDefault();
        e.stopImmediatePropagation && e.stopImmediatePropagation();
        const returnValue = 'Unsaved changes will be lost!';
        e.returnValue = returnValue;
        return returnValue;
      } else {
        this.setState({ showConfirmModalPath: e.pathname });
        return false;
      }
    } else {
      this.cleanUp();
    }
  };

  cleanUp = () => {
    this.unblock && this.unblock();
  };

  handleCampaignToggle = () => {
    const { campaignInfo, updateCampaignInfo } = this.props;
    updateCampaignInfo({ isActive: !campaignInfo.isActive });
  };

  handleCampaignNameChange = e => {
    const { updateCampaignInfo } = this.props;
    updateCampaignInfo({ name: e.target.value });
  };

  handleCampaignInfoChange = info => {
    const { updateCampaignInfo, campaignInfo } = this.props;
    // const [updatedLaunchGroup] = Object.values(info);
    // const standardLaunchGroups = variations.filter(({ key, forStandard = false }) => updatedLaunchGroup.indexOf(key) !== -1 && forStandard);
    // const campaingEmailsUserTypes = _.get(campaignInfo, 'campaignEmails');
    // const corporateUType = chain(campaingEmailsUserTypes).map('standardUserType').filter(uType => uType.indexOf('Corporate Partner & Employer') !== -1).value();
    //
    // if(standardLaunchGroups && standardLaunchGroups.length && corporateUType && corporateUType.length){
    //   notify(`Standard launch group can't have Corporate Partner & Employer usertype`,'error');
    //   return true;
    // }
    updateCampaignInfo(info);
  };

  goBack = () => {
    const { history } = this.props;
    history.push('/campaigns');
  };

  handleSave = () => {
    const { campaignInfo, handleCreateOrUpdateCampaignInfo } = this.props;
    const { name, module, type } = campaignInfo;
    const isProgramCampaign = type === 'program_campaign';
    if (isEmpty(name)) {
      notify(`Please fill out the 'Campaign name' field.`, 'error');
    } else if (!isProgramCampaign && isEmpty(module)) {
      notify(`Please fill out the 'Require Module' field.`, 'error');
    } else {
      if (isProgramCampaign) {
        handleCreateOrUpdateCampaignInfo({
          ...campaignInfo,
          clusterLaunchGroup: null,
          launchGroup: null,
          adminTags: null,
          module: isProgramCampaign ? ['programs'] : module,
        });

        return;
      }

      handleCreateOrUpdateCampaignInfo({
        ...campaignInfo,
        module: isProgramCampaign ? ['programs'] : module,
      });
    }
  };

  showDeleteModal = value => {
    this.setState({
      deleteEmail: value,
    });
  };

  onDeleteCampaignEmail = record => {
    const { handleDeleteCampaignEmail } = this.props;
    handleDeleteCampaignEmail(record);
    this.setState({ deleteEmail: null });
  };

  handleAddNewTemplateClick = () => {
    const { campaignInfo, history } = this.props;
    this.unblock();
    const path = `/campaigns/${campaignInfo.id || 'new'}/templates`;
    history.push(path);
  };

  handleEditCampaignEmail = ({ id, draftId }) => {
    const { handleOpenEmailEditor, campaignInfo } = this.props;
    const path = `/campaigns/${campaignInfo.id || 'new'}`;
    this.unblock();
    handleOpenEmailEditor({
      id: id,
      type: 'campaignEmail',
      redirectPath: path,
    });
  };

  handleColumnValueChanged = (record, updatedData, skipApiSave) => {
    const { handleUpdateEmailTemplate } = this.props;
    handleUpdateEmailTemplate({
      record,
      updatedData,
      skipApiSave,
    });
  };
  isSaveDisabled = () => {
    const { campaignInfo, savedCampaignInfo } = this.props;
    return isEqual(
      omit(campaignInfo, 'campaignEmails'),
      omit(savedCampaignInfo, 'campaignEmails')
    );
  };

  handleConfirmCloseModalOk = path => {
    const { history } = this.props;
    const { resetCampaignInfo } = this.props;
    resetCampaignInfo();
    this.unblock();
    if (path && path !== '') history.push(path);
    else history.push('/campaigns');
  };

  makePreSelectedAdminTags = () => {
    const { campaignInfo: { adminTags = [] } = {} } = this.props;

    return (adminTags || []).filter(t =>
      this.state.adminTags.some(({ key }) => key === t)
    );
  };

  render() {
    const {
      campaignInfo = {},
      campaignInfoLoading,
      savingCampaignInfo,
    } = this.props;
    const { showConfirmModalPath, deleteEmail, adminTags } = this.state;
    const {
      id,
      name,
      isActive = false,
      module,
      campaignEmails = [],
      isTestCampaign,
      type = 'am_campaign',
      launchGroup,
      clusterLaunchGroup,
    } = campaignInfo;

    const isProgramCampaign = type === 'program_campaign';

    return campaignInfoLoading ? (
      <Loader />
    ) : (
      <div className="campaigns_new">
        <Content>
          <CampaignHeader
            name={name}
            isActive={isActive}
            onActiveToggle={this.handleCampaignToggle}
            onInputChange={this.handleCampaignNameChange}
          />
        </Content>
        <Content>
          <CampaignEmailsTable
            id={id}
            dataSource={orderBy(campaignEmails, ['delay'], ['asc'])}
            onDelete={this.showDeleteModal}
            onEdit={this.handleEditCampaignEmail}
            onColumnChange={this.handleInputThrottled}
            onAddNewTemplateClicked={this.handleAddNewTemplateClick}
          />
        </Content>
        <Content>
          <Card title="Campaign Hubs">
              <p>
                Unless you filter your hub selection down by module or Enablement Group,
                this campaign will go live to every single hub. We strongly recommend 
                filtering by Enablement Group.
              </p>
              <Row>
               <Col gutter={8}>
                <label
                  className="campaigns_new_require_module--label"
                  htmlFor="type"
                >
                  Visible To
                </label>
              </Col>
              <Radio.Group
                id="type"
                onChange={e => {
                  const { value } = e.target;
                  this.handleCampaignInfoChange({ type: value });
                }}
                value={type}
              >
                <Radio value={'am_campaign'}>
                  Automated Marketing Campaign
                </Radio>
                <Radio value={'program_campaign'}>Programs</Radio>
                <Radio value={'public_campaign'}>Visible to All</Radio>
              </Radio.Group>
              <p>
                Select “Visible to All” unless this campaign is intended for hubs that have opted into Automated Marketing.
              </p>
            </Row>
            {!isProgramCampaign ? (
              <Row style={{ marginTop: '16px', marginBottom: '8px' }}>
                <Col>
                  <label 
                className="campaigns_new_require_module--label"
                htmlFor="module">
                  Require Module
                  </label>
                </Col>
                <Col span={6}>
                  <Select
                    id="module"
                    placeholder="Select module(s) required for this campaign"
                    value={module}
                    mode="multiple"
                    onChange={value =>
                      this.handleCampaignInfoChange({ module: value })
                    }
                    getPopupContainer={node => node.parentNode}
                    allowClear={true}
                    style={{ width: '100%'}}
                  >
                    {[...subModuleOptions, ...moduleOptions].map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            ) : null}
            {!isProgramCampaign ? (
              <Row style={{ marginTop: '16px' }}>
                <Col>
                  <label
                    className="campaigns_new_module--label"
                    htmlFor="cluster_launch_group"
                  >
                    Cluster Launch Group
                  </label>
                </Col>
                <Col span={7}>
                  <MultiSelect
                    id="launch_group"
                    optionFilterProp="children"
                    dataSource={variations}
                    value={clusterLaunchGroup}
                    onChange={value => { 
                      const  clusterLaunchGroup = isEmpty(value) ? null : value;
                      this.handleCampaignInfoChange({ clusterLaunchGroup });
                    }}
                  />
                </Col>
              </Row>
            ) : null}
            {!isProgramCampaign ? (
              <Row style={{ marginTop: '16px' }}>
                <Col>
                  <label
                    className="campaigns_new_module--label"
                    htmlFor="launch_group"
                  >
                    Hub Launch Group
                  </label>
                </Col>
                <Col span={7}>
                  <MultiSelect
                    id="launch_group"
                    optionFilterProp="children"
                    dataSource={variations}
                    value={launchGroup}
                    onChange={value => {
                      const  launchGroup = isEmpty(value) ? null : value;
                      this.handleCampaignInfoChange({ launchGroup });
                    }}
                  />
                </Col>
              </Row>
            ) : null}
            {!isProgramCampaign && (
              <Row style={{ marginTop: '16px' }}>
                <Col>
                  <label
                    className="campaigns_new_module--label"
                    htmlFor="hub_admin_tag"
                  >
                    Hub Admin Tag
                  </label>
                </Col>
                <Col span={7}>
                  <MultiSelect
                    id="hub_admin_tag"
                    optionFilterProp="children"
                    placeholder="Filter by Admin Tags"
                    dataSource={adminTags}
                    value={this.makePreSelectedAdminTags()}
                    onChange={value => {
                      const adminTags = isEmpty(value) ? null : value;
                      this.handleCampaignInfoChange({ adminTags });
                    }}
                  />
                </Col>
              </Row>
            )}
            {!isProgramCampaign ? (
              <Col span={20} style={{ marginTop: '16px' }}>
                <Checkbox
                  id="isTestCampaign"
                  checked={isTestCampaign}
                  onChange={e =>
                    this.handleCampaignInfoChange({
                      isTestCampaign: e.target.checked,
                    })
                  }
                >
                  <span  className="campaigns_new_module--label">
                    Only Send To Campaign Test Hubs
                  </span>
                </Checkbox>
                <p>
                  If this box is checked, this campaign will only be sent to hubs
                  where the showTestCampaign flag is enabled. To use, first enable
                  the flag on your test hub, then check this box and send the
                  campaign. The flag should only be enabled on internal test hubs.
              </p>
              </Col>
            ) : null}
          </Card>
        </Content>

        <Footer className="campaigns_new_footer">
          <div className="campaigns_new_footer--btn-group">
            <Button onClick={this.goBack}>Cancel</Button>
            <Button
              type="primary"
              disabled={this.isSaveDisabled()}
              onClick={this.handleSave}
              loading={savingCampaignInfo}
            >
              Save
            </Button>
          </div>
        </Footer>
        <ConfirmCloseModal
          path={showConfirmModalPath}
          onCancel={() => this.setState({ showConfirmModalPath: null })}
          onOk={this.handleConfirmCloseModalOk}
        />
        <ConfirmDeleteModal
          path={deleteEmail}
          onCancel={() => this.setState({ deleteEmail: null })}
          onOk={this.onDeleteCampaignEmail}
          description={
            <div>
              Once you delete this email, it will be removed from this campaign
              and any settings associated with it will be lost.
              <br />
              <br />
              Note that deleting this email will not delete it from any hubs
              that have already enabled this campaign.
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  automatedMarketing: {
    campaign: {
      campaignInfo,
      savedCampaignInfo,
      campaignInfoLoading,
      savingCampaignInfo,
    },
  },
  adminTag: { adminTags, adminTagsLoading },
}) => ({
  campaignInfo,
  savedCampaignInfo,
  campaignInfoLoading,
  savingCampaignInfo,
  adminTags,
  adminTagsLoading,
});

const mapDispatchToProps = {
  handleGetCampaignInfo,
  updateCampaignInfo,
  handleDeleteCampaignEmail,
  handleCreateOrUpdateCampaignInfo,
  handleOpenEmailEditor,
  handleUpdateEmailTemplate,
  resetCampaignInfo,
  fetchAdminTags,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CampaignInfo)
);
