const setItems = data => {
  data.forEach(item => {
    if (Array.isArray(item)) {
      const [key, value] = item;
      setItem(key, value);
    }
  });
};

const getItems = keys => keys.map(getItem);

const removeItems = keys => keys.forEach(removeItem);

export const getItem = item =>
  Array.isArray(item) ? getItems(item) : localStorage.getItem(item);

export const setItem = (item, value) => {
  if (Array.isArray(item) && !value) {
    setItems(item);
  } else {
    localStorage.setItem(item, value);
  }
};
export const removeItem = item => {
  if (typeof item === 'boolean' && item) {
    removeItems(Object.keys(localStorage).filter(el => localStorage.hasOwnProperty(el)))
  } else {
    localStorage.removeItem(item);
  }
}
