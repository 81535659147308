import {pick} from 'lodash';
import { STORE_ADMIN_DETAIL } from '../actions/admin';

const initialState = {};

const admin = (state = initialState, { type, data }) => {
  switch (type) {
    case STORE_ADMIN_DETAIL:
      return {
        ...data
      }
    default: return state
  }
};

export const getAdminDetails = ({admin}, keys) => pick(admin, keys);

export default admin;
