/* eslint-disable import/prefer-default-export */
export const MLServices = [
  {
    name: 'LLM Recommendation',
    value: 'pg_ml_llm',
    api: [
      {
        name: 'Users Recommendations',
        value: 'users-recommendations',
      },
      {
        name: 'Users Weighted Recommendations',
        value: 'users-weighted-recommendations',
      },
      {
        name: 'Users Weighted Search',
        value: 'users-weighted-search',
      },
      {
        name: 'Users Via Career Path Weighted Search',
        value: 'users-cp-weighted-search',
      },
      {
        name: 'Jobs recommendation ES',
        value: 'jobs-recommendations-es',
      },
      {
        name: 'Jobs search ES',
        value: 'jobs-search-es',
      },
    ],
  },
  {
    name: 'ML Recommendation',
    value: 'recommendation',
    api: [
      {
        name: 'Recommendations',
        value: 'recommendations',
      },
      {
        name: 'Search',
        value: 'search',
      },
    ],
  },
  {
    name: 'User Connections',
    value: 'userConnection',
    api: [
      {
        name: 'User Connection Search (with career journey)',
        value: 'user-connections-search',
      },
    ],
  },
];
