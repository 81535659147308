import React from 'react';
import {
  Input,
  Button,
  Divider,
  TimePicker,
  DatePicker,
  Select,
  Spin,
  InputNumber,
  Tooltip,
  Icon,
  Table,
  Checkbox,
  Dropdown,
  Menu,
} from 'antd';
import { isString, get, isArray } from 'lodash';
import moment from 'moment';

import Content from '../../../component/Content';

import {
  moduleOptions,
  TIME_FORMAT,
  DATE_FORMAT,
} from '../utils';
import { standardUserTypeOptions } from '../../../utils/helpers';

const { Option } = Select;

const columns = [
  {
    title: 'Hub ID',
    dataIndex: 'id',
    sorter: (a, b) => a.id - b.id,
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Hub Name',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Hub Domain',
    dataIndex: 'domain',
    sorter: (a, b) => a.domain.localeCompare(b.domain),
  },
  {
    title: 'User Count',
    dataIndex: 'userCount',
    sorter: (a, b) => a.userCount - b.userCount,
  },
  {
    title: 'Recent Emails',
    dataIndex: 'marketingEmails',
    render: (value, record) => {
      let nbValidItems = 0;
      if (isArray(value) && value.length) {
        const sortedItems = value.sort((a, b) => {
          if (!a || !a.scheduled_time) {
            return -1;
          } else if (!b || !b.scheduled_time) {
            return 1;
          } else {
            return moment(a.scheduled_time).diff(b.scheduled_time);
          }
        });
        const menu = (
          <Menu>
            {sortedItems.map(item => {
              if (item) nbValidItems += 1;
              return (
                item && (
                  <Menu.Item key={item.id}>
                    {moment(item.scheduled_time).format(DATE_FORMAT)} -{' '}
                    {item.name}
                  </Menu.Item>
                )
              );
            })}
          </Menu>
        );

        if (nbValidItems)
          return (
            <Dropdown
              overlay={menu}
              getPopupContainer={node => node.parentNode}
            >
              <a
                className="ant-dropdown-link"
                onClick={e => e.preventDefault()}
              >
                Recent {nbValidItems} email
                {nbValidItems === 1 ? '' : 's'} <Icon type="down" />
              </a>
            </Dropdown>
          );
      }

      return 'No recent emails';
    },
  },
];

class EmailConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = { email: null };
  }

  render() {
    const {
      handleFormInputChange,
      draftData,
      handleSendTestEmail,
      disabled,
      statsLoading,
      stats,
      emails,
      emailsLoading,
      onGetStatsClick,
      hubs = [],
    } = this.props;
    const { email } = this.state;

    const {
      date,
      startTime,
      endTime,
      importStatus,
      standardUserType,
      module,
      minAudience = 100,
      excludeEmails = [],
      includeEmails = [],
      selectedHubs = [],
      currentRole = [],
      canPostProject = false,
      filterHubs = [],
      projectCompanyOptional = false,
    } = draftData || {};

    const emailOptions = [];
    (emails || [])
      .sort((a, b) => {
        const aDate = get(a, 'draftData.date');
        const bDate = get(b, 'draftData.date');
        if (!aDate) {
          return 1;
        } else if (!bDate) {
          return -1;
        } else {
          return moment(aDate).diff(bDate) * -1;
        }
      })
      .forEach(em => {
        const dt = get(em, 'draftData.date');
        if (em.status !== 'draft' && dt) {
          emailOptions.push(
            <Option key={em.id} value={em.id}>
              {`${dt} - ${em.name}`}
            </Option>
          );
        }
      });

    const hubOptions = (hubs || []).map(hub => (
      <Option key={hub.id} value={hub.id}>
        {`${hub.id} - ${hub.name}`}
      </Option>
    ));

    const rowSelection = {
      selectedRowKeys: selectedHubs,
      onChange: selectedRowKeys =>
        handleFormInputChange(selectedRowKeys, 'selectedHubs'),
    };

    return (
      <Content>
        <h2>New Email</h2>
        <Divider />
        <p>
          First, send a test below. This will send a test email from 10 random
          hubs who have opted in so you can preview the template from different
          hubs.
        </p>
        <div className="am__email-editor__form__group am__email-editor__test">
          <Input
            placeholder="Your Email"
            onChange={e => this.setState({ email: e.target.value })}
            disabled={disabled}
          />
          <Button
            onClick={() => email && handleSendTestEmail(email)}
            disabled={disabled}
          >
            Send Tests
          </Button>
        </div>
        <Divider />
        <p>Second, select a sending time window.</p>
        <div className="am__email-editor__time">
          <div className="am__email-editor__time__item">
            <label className="am__label--required" htmlFor="date">
              Date
            </label>
            <DatePicker
              id="date"
              format={DATE_FORMAT}
              value={isString(date) ? moment(date, DATE_FORMAT) : date}
              onChange={(date, dateString) =>
                handleFormInputChange(dateString, 'date')
              }
              disabled={disabled}
              disabledDate={current =>
                moment(current).diff(moment(), 'days') < 0
              }
            />
          </div>
          <div className="am__email-editor__time__item">
            <label className="am__label--required" htmlFor="startTime">
              Between (Hub Time)
            </label>
            <TimePicker
              id="startTime"
              use12Hours
              format={TIME_FORMAT}
              value={
                isString(startTime) ? moment(startTime, TIME_FORMAT) : startTime
              }
              onChange={(time, timeString) =>
                handleFormInputChange(timeString, 'startTime')
              }
              disabled={disabled}
            />
            <TimePicker
              use12Hours
              format={TIME_FORMAT}
              value={isString(endTime) ? moment(endTime, TIME_FORMAT) : endTime}
              onChange={(time, timeString) =>
                handleFormInputChange(timeString, 'endTime')
              }
              disabled={disabled}
            />
          </div>
        </div>
        <Divider />
        <div className="am__email-editor__filters">
          <h3>Audience targeting</h3>
          <b>User Filters</b>
          {/*<p><Icon type="warning" style={{color: 'red', fontSize: '1.5em'}} /> Emails to Corporate Partner & Employer usertype will be skipped for Standard Hubs</p>*/}
          <ul>
            <li>
              <label className="am__label--required" htmlFor="importStatus">
                Joined vs Imported
              </label>
              <Select
                id="importStatus"
                placeholder="Select an option"
                value={importStatus}
                onChange={value => handleFormInputChange(value, 'importStatus')}
                disabled={disabled || statsLoading}
                getPopupContainer={node => node.parentNode}
              >
                <Option value="joined">Only joined users</Option>
                <Option value="imported">Only imported users</Option>
                <Option value="joinedAndImported">
                  Joined and imported users
                </Option>
              </Select>
            </li>
            <li>
              <label className="am__label--required" htmlFor="standardUserType">
                Standardized user types
              </label>
              <Select
                id="standardUserType"
                placeholder="Select an user type"
                value={standardUserType}
                onChange={value =>
                  handleFormInputChange(value, 'standardUserType')
                }
                disabled={disabled || statsLoading}
                getPopupContainer={node => node.parentNode}
              >
                {standardUserTypeOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </li>
            <li>
              <label htmlFor="currentRole">
                Current Role
                <Tooltip title="Search current role by keyword(s)">
                  <Icon type="info-circle" />
                </Tooltip>
              </label>
              <Select
                id="currentRole"
                placeholder="Add search keyword(s)"
                value={currentRole}
                onChange={value => handleFormInputChange(value, 'currentRole')}
                disabled={disabled || statsLoading}
                getPopupContainer={node => node.parentNode}
                mode="tags"
                tokenSeparators={[',']}
              />
            </li>
            <li>
              <label htmlFor="canPostProject">
                Can post project
                <Tooltip title="User has necessary permissions to post project">
                  <Icon type="info-circle" />
                </Tooltip>
              </label>
              <Checkbox
                id="canPostProject"
                checked={canPostProject}
                onChange={e =>
                  handleFormInputChange(e.target.checked, 'canPostProject')
                }
              />
            </li>
          </ul>
          <b>Hub Filters</b>
          <ul>
            <li>
              <label htmlFor="module">Require module</label>
              <Select
                id="module"
                placeholder="Select a module"
                value={module}
                onChange={value => handleFormInputChange(value, 'module')}
                disabled={disabled || statsLoading}
                getPopupContainer={node => node.parentNode}
                allowClear={true}
              >
                {moduleOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </li>
            <li>
              <label htmlFor="minAudience">
                Minimum audience size
                <Tooltip title="If set, this email will NOT be sent to any hub where the audience is less than the number. Use this to limit sending emails to hubs that haven't launched.">
                  <Icon type="info-circle" />
                </Tooltip>
              </label>
              <InputNumber
                id="minAudience"
                min={1}
                step={1}
                defaultValue={100}
                value={minAudience || 100}
                onChange={value => handleFormInputChange(value, 'minAudience')}
                disabled={disabled || statsLoading}
              />
            </li>
            <li>
              <label htmlFor="excludeEmails">
                Exclude previous emails
                <Tooltip title="If you select any of the emails to the right, any hub which was sent the checked emails will NOT be targeted with the current email you are sending.">
                  <Icon type="info-circle" />
                </Tooltip>
              </label>
              <Select
                id="excludeEmails"
                placeholder="Select a emails"
                value={excludeEmails || []}
                onChange={value =>
                  handleFormInputChange(value, 'excludeEmails')
                }
                disabled={disabled || statsLoading || emailsLoading}
                mode="multiple"
                allowClear={true}
                getPopupContainer={node => node.parentNode}
                dropdownMatchSelectWidth={false}
              >
                {emailOptions}
              </Select>
            </li>
            <li>
              <label htmlFor="includeEmails">
                Only send if previous emails
                <Tooltip title="If you select any of the emails to the right, ONLY hubs which were sent this previous email will get this next email. Hubs which did not get the checked emails will be excluded from the mailing.">
                  <Icon type="info-circle" />
                </Tooltip>
              </label>
              <Select
                id="includeEmails"
                placeholder="Select a emails"
                value={includeEmails || []}
                onChange={value =>
                  handleFormInputChange(value, 'includeEmails')
                }
                disabled={disabled || statsLoading || emailsLoading}
                mode="multiple"
                allowClear={true}
                getPopupContainer={node => node.parentNode}
                dropdownMatchSelectWidth={false}
              >
                {emailOptions}
              </Select>
            </li>
            <li>
              <label htmlFor="filterHubs">Select Hubs</label>
              <Select
                id="filterHubs"
                placeholder="Select a hubs"
                value={filterHubs || []}
                onChange={value => handleFormInputChange(value, 'filterHubs')}
                disabled={disabled || statsLoading}
                mode="multiple"
                allowClear={true}
                getPopupContainer={node => node.parentNode}
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {hubOptions}
              </Select>
            </li>
            <li>
              <label htmlFor="projectCompanyOptional">
                Company optional for projects
                <Tooltip title="Company is optional for posting a project">
                  <Icon type="info-circle" />
                </Tooltip>
              </label>
              <Checkbox
                id="projectCompanyOptional"
                checked={projectCompanyOptional}
                onChange={e =>
                  handleFormInputChange(
                    e.target.checked,
                    'projectCompanyOptional'
                  )
                }
              />
            </li>
            <li>
              {importStatus && standardUserType ? (
                <Button onClick={onGetStatsClick}>Get Stats</Button>
              ) : (
                <Tooltip title="Select all required fields">
                  <Button disabled={true}>Get Stats</Button>
                </Tooltip>
              )}
            </li>
          </ul>
        </div>
        <Divider />
        <div className="am__email-editor__stats">
          <h3>
            Stats{' '}
            <Tooltip title="Emails will be triggered only for the hubs where Bridges and Automated Marketing is enabled.">
              <Icon type="info-circle" />
            </Tooltip>
          </h3>
          {statsLoading ? (
            <Spin />
          ) : (
            <p>
              The email would be sent to <b>{get(stats, 'nbUsers', 0)} users</b>{' '}
              across <b>{get(stats, 'nbHubs', 0)} hubs</b>.
            </p>
          )}
          {!statsLoading && get(stats, 'nbHubs', 0) > 0 ? (
            <Table
              columns={columns}
              dataSource={get(stats, 'details', [])}
              rowKey="id"
              rowSelection={rowSelection}
            />
          ) : (
            ''
          )}
        </div>
      </Content>
    );
  }
}

export default EmailConfig;
