import React, { useEffect, useState } from 'react';
import {
  Spin,
  Form,
  Icon,
  Switch,
  Input,
  InputNumber,
  Button,
  Tooltip,
  Select,
  Row,
  Col,
  Tag,
} from 'antd';
import { filter, indexOf, isEmpty, isString, map, sortBy } from 'lodash';
import {
  columnsToDrop,
  isColumnDataRequired,
  getMinValueForInputNumber,
  getMaxValueForInputNumber,
  getTooltipForKey,
  parseString,
  validateEducationalReq,
  validateCustom,
  isStringValid,
  formatFieldName,
  validateURLs,
  fieldsOrder,
} from '../utils';

// eslint-disable-next-line import/no-named-as-default
import notify from '../../../utils/notify';

const { Option } = Select;

const CareerPathForm = ({
  form,
  careerPathWithAssociatedData,
  auxiliaryDataForCareerPaths,
  onSave,
  onCancel,
  isLoading,
  isEditing,
  changedFields,
  revertCareerPathChange,
  changeLogId,
  editingKey: careerPathId,
}) => {
  const { standardDataRelationships = {} } = careerPathWithAssociatedData;
  const [formElements, setFormElements] = useState([]);
  const changedFieldNames = changedFields.map(e => e.path.split('/')[1]);
  const customFields = [
    'jobResponsibilities',
    'alternativeTitles',
    'dayInLifeVideos',
    'jobFunction',
    'resources',
    'twitterAccounts',
    'tags',
  ];

  useEffect(() => {
    if (
      !isLoading.standardDataForCareerPaths &&
      !isLoading.careerPathWithAssociatedData &&
      !isLoading.careerPathChangeLog &&
      !isEmpty(auxiliaryDataForCareerPaths)
    ) {
      // create elements for career path data
      // [TODO] write proper validation rule
      const allElements = Object.entries(careerPathWithAssociatedData).map(
        ([key, value]) => {
          if (columnsToDrop.includes(key)) return null;
          const {
            jobFunctions,
            personalities,
            // abilities,
            // skillsRequired,
            skillsAbilities,
            educationalRequirements,
            occupations,
          } = auxiliaryDataForCareerPaths;
          let educationalRequirementsObj;
          if (key === 'educationalRequirements') {
            educationalRequirementsObj = educationalRequirements.reduce(
              (a, i) => ({ ...a, [i]: false }),
              {}
            );
            for (const { educationLevel } of value) {
              educationalRequirementsObj[educationLevel] = true;
            }
          }

          const dropdownObj = {
            personalities,
            // abilities,
            // skillsRequired,
            skillsAbilities,
            occupations,
          };

          let inputElement;

          const defaultInputElement = form.getFieldDecorator(`${key}`, {
            rules: [
              {
                required: isColumnDataRequired(key),
                message: `${
                  key !== 'imageUrl' ? key : 'Thumbnail Url'
                } is required`,
              },
              {
                validator: (rule, val, callback) => {
                  try {
                    if (key === 'title' || key === 'description')
                      isStringValid(val, rule.field);
                    callback();
                  } catch (e) {
                    callback(e);
                  }
                },
              },
            ],
            initialValue: isString(value) ? value : JSON.stringify(value),
          })(<Input disabled={!isEditing} />);
          const defaultInputLabel = `${key}`;

          let currentValue;
          console.log('--key--', key);
          if (customFields.includes(key)) {
            const val = form.getFieldValue(key);
            console.log('--val--', val);
            currentValue = parseString(val);
          }
          const handleCustomInput = (e, idx) => {
            const fieldVal = form.getFieldValue(e.target.name);
            const val = e.target.value;
            const arr = parseString(fieldVal);
            if (arr.length === 0) arr[0] = '';
            if (val) arr[idx] = val;
            else arr.splice(idx, 1);
            form.setFieldsValue({
              [e.target.name]: JSON.stringify(arr),
            });
          };

          const handleTagClick = (e, index) => {
            const fieldVal = form.getFieldValue(e);
            const arr = parseString(fieldVal);
            arr.splice(index, 1);
            form.setFieldsValue({
              [e]: JSON.stringify(arr),
            });
          };

          const onAddClick = (e, val = '') => {
            const fieldVal = form.getFieldValue(e);
            const arr = parseString(fieldVal);
            arr.push(val);
            form.setFieldsValue({
              [e]: arr,
            });
          };
          const handleEducationLevel = (e, idx, mode) => {
            if (educationalRequirementsObj[e])
              educationalRequirementsObj[e] = true;
            const fieldValue = form.getFieldValue(key);
            if (mode === 'level') fieldValue[idx].educationLevel = e;
            else fieldValue[idx].value = e;
            form.setFieldsValue({
              [key]: fieldValue,
            });
          };
          const deleteCurrent = idx => {
            let fieldValue = form.getFieldValue(key);
            if (typeof fieldValue === 'string') {
              fieldValue = parseString(fieldValue);
              fieldValue.splice(idx, 1);
              fieldValue = JSON.stringify(fieldValue);
            } else fieldValue.splice(idx, 1);
            form.setFieldsValue({
              [key]: fieldValue,
            });
          };
          const validateMedianSalaryHigh = (rule, val, callback) => {
            try {
              if (
                val < form.getFieldValue('medianSalaryLow') &&
                key === 'medianSalaryHigh'
              )
                throw new Error(
                  'Median salary high cannot be lesser than median salary low'
                );
              callback();
            } catch (e) {
              callback(e);
            }
          };
          const handleJobFunctionChange = (e, idx) => {
            const fieldVal = form.getFieldValue(key);
            const arr = parseString(fieldVal);
            if (arr[idx]) arr[idx] = e;
            else {
              if (idx === 2 && arr.length === 1) arr.push(null);
              arr.push(e);
            }
            form.setFieldsValue({
              [key]: arr,
            });
          };

          const getInputLabel = dataItemKey => (
            <span>
              {formatFieldName(dataItemKey)}
              &nbsp;
              {getTooltipForKey(dataItemKey) && (
                <>
                  <Tooltip title={getTooltipForKey(dataItemKey)}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </>
              )}
              {dataItemKey === 'educationalRequirements' && (
                <span style={{ float: 'right', marginRight: '11%' }}>
                  % Value
                </span>
              )}
            </span>
          );

          // on change description of personalities & skillsRequired
          const handleCustomFieldDescriptionChange = (title, description) => {
            const customFieldValues = form.getFieldValue(key);
            const itemIndex = customFieldValues.findIndex(
              item => item.title === title
            );
            customFieldValues[itemIndex] = { title, description };
            form.setFieldsValue({
              [key]: customFieldValues,
            });
          };

          const handleOccupationFieldChange = selectedValues => {
            form.setFieldsValue({
              occupations: selectedValues,
            });
          };

          const handleCustomFieldChange = selectedValues => {
            const arr = selectedValues.map(selectedValue => {
              const itemIndex = form
                .getFieldValue(key)
                .findIndex(item => item.title === selectedValue);
              return {
                title: selectedValue,
                description:
                  itemIndex === -1
                    ? ''
                    : form.getFieldValue(key)[itemIndex].description,
              };
            });
            form.setFieldsValue({
              [key]: arr,
            });
          };

          console.log('--personalities---', personalities);
          console.log('---values--', form.getFieldValue('personalities'));

          switch (key) {
            case 'highDemand':
              inputElement = form.getFieldDecorator(`${key}`, {
                rules: [
                  {
                    required: isColumnDataRequired(key),
                    message: `${key} is required`,
                  },
                ],
                valuePropName: 'checked',
                initialValue: !!value,
              })(<Switch disabled={!isEditing} />);
              break;

            case 'medianSalaryHigh':
            case 'medianSalaryLow':
              inputElement = form.getFieldDecorator(`${key}`, {
                rules: [
                  {
                    required: isColumnDataRequired(key),
                    message: `${key} is required`,
                  },
                  {
                    validator: validateMedianSalaryHigh,
                  },
                ],
                initialValue:
                  getMinValueForInputNumber(key) <= value
                    ? value
                    : getMinValueForInputNumber(key),
              })(
                <InputNumber
                  min={getMinValueForInputNumber(key)}
                  max={getMaxValueForInputNumber(key)}
                  disabled={!isEditing}
                  formatter={val =>
                    `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={val => val.replace(/\$\s?|(,*)/g, '')}
                />
              );
              break;

            case 'alternativeTitles':
            case 'jobResponsibilities':
            case 'dayInLifeVideos':
              inputElement = form.getFieldDecorator(`${key}`, {
                rules: [
                  {
                    required: isColumnDataRequired(key),
                    message: `${key} is required`,
                  },
                  {
                    validator: validateCustom,
                  },
                ],
                initialValue: JSON.stringify(value),
              })(
                <>
                  {(currentValue.length > 0 ? currentValue : ['']).map(
                    (val, idx) => (
                      <>
                        <Input
                          value={val}
                          onChange={e => handleCustomInput(e, idx)}
                          name={key}
                          disabled={!isEditing}
                        />
                      </>
                    )
                  )}
                  <Tooltip title="add">
                    <Button
                      type="primary"
                      shape="circle"
                      icon="plus"
                      onClick={e => onAddClick(e.target.value)}
                      value={key}
                      disabled={!isEditing}
                    />
                  </Tooltip>
                </>
              );
              break;

            case 'educationalRequirements':
              inputElement = form.getFieldDecorator(`${key}`, {
                rules: [
                  {
                    required: isColumnDataRequired(key),
                    message: `${key} is required`,
                  },
                  {
                    validator: validateEducationalReq,
                  },
                ],
                initialValue: value,
              })(
                <>
                  {value.length > 0
                    ? value.map(({ value: val, educationLevel: lvl }, idx) => (
                        <div style={{ display: 'flex', maxWidth: '100%' }}>
                          <Select
                            showSearch
                            onChange={e =>
                              handleEducationLevel(e, idx, 'level')
                            }
                            value={lvl}
                            style={{ minWidth: '80%' }}
                            disabled={!isEditing}
                          >
                            {Object.entries(educationalRequirementsObj).map(
                              ([educationLevel, selected]) =>
                                !selected && (
                                  <Option key={educationLevel}>
                                    {educationLevel}
                                  </Option>
                                )
                            )}
                          </Select>
                          <InputNumber
                            disabled={!lvl || !isEditing}
                            min={0}
                            max={100}
                            step={1}
                            value={val}
                            style={{ minWidth: '15%' }}
                            onChange={e => handleEducationLevel(e, idx)}
                          />
                          <Button
                            type="primary"
                            shape="circle"
                            icon="delete"
                            style={{ marginLeft: '5px', minWidth: '5%' }}
                            onClick={e => deleteCurrent(idx)}
                            disabled={!lvl || !isEditing}
                          />
                        </div>
                      ))
                    : onAddClick(key, { value: 0, educationLevel: '' })}
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '5px',
                    }}
                  >
                    {form.getFieldValue(key).length < 5 && (
                      <Tooltip title="add">
                        <Button
                          type="primary"
                          shape="circle"
                          icon="plus"
                          onClick={e =>
                            onAddClick(e.target.value, {
                              value: 0,
                              educationLevel: '',
                            })
                          }
                          value={key}
                          disabled={!isEditing}
                        />
                      </Tooltip>
                    )}
                    <Input
                      style={{ maxWidth: '25%', marginRight: '5%' }}
                      value={value.reduce((prev, curr) => prev + curr.value, 0)}
                      disabled={!isEditing}
                      addonBefore="Total:"
                    />
                  </span>
                </>
              );
              break;

            case 'jobFunction':
              inputElement = form.getFieldDecorator(`${key}`, {
                rules: [
                  {
                    required: isColumnDataRequired(key),
                    message: `${key} is required`,
                  },
                  {
                    validator: (rule, val, callback) => {
                      try {
                        const v = parseString(val);
                        if (v.length === 0)
                          throw new Error(
                            `"${formatFieldName(rule.field)}" cannot be empty.`
                          );
                        callback();
                      } catch (e) {
                        callback(e);
                      }
                    },
                  },
                ],
                initialValue: JSON.stringify(value),
              })(
                <>
                  {[...Array(3)].map((_, i) => (
                    <div style={{ display: 'flex', maxWidth: '100%' }}>
                      <Select
                        showSearch
                        placeholder="Please select"
                        disabled={!isEditing}
                        value={jobFunctions[currentValue[i]] || currentValue[i]}
                        key={currentValue[i]}
                        onChange={e => handleJobFunctionChange(e, i)}
                      >
                        {Object.entries(jobFunctions).map(
                          ([slug, jobFunctionName]) =>
                            !form.getFieldValue(key).includes(slug) && (
                              <Option key={slug} value={slug}>
                                {jobFunctionName}
                              </Option>
                            )
                        )}
                      </Select>
                      <Button
                        type="primary"
                        shape="circle"
                        icon="delete"
                        style={{ marginLeft: '5px' }}
                        onClick={e => deleteCurrent(i)}
                        disabled={!isEditing || !form.getFieldValue(key)}
                      />
                    </div>
                  ))}
                </>
              );
              break;

            case 'abilities':
              inputElement = form.getFieldDecorator(`${key}`, {
                rules: [
                  {
                    required: isColumnDataRequired(key),
                    message: `${key} is required`,
                  },
                ],
                initialValue: value,
              })(
                <Select
                  showSearch
                  mode="tags"
                  placeholder="Please select"
                  disabled={!isEditing}
                  getPopupContainer={node => node.parentNode}
                >
                  {dropdownObj[key].map(val => (
                    <Option key={val} value={val}>
                      {val}
                    </Option>
                  ))}
                </Select>
              );
              break;

            case 'skillsAbilities':
            case 'personalities':
              inputElement = form.getFieldDecorator(`${key}`, {
                rules: [
                  {
                    required: isColumnDataRequired(key),
                    message: `${key} is required`,
                  },
                ],
                initialValue: value,
              })(
                <>
                  <Select
                    showSearch
                    mode="tags"
                    placeholder="Please select"
                    disabled={!isEditing}
                    onChange={handleCustomFieldChange}
                    defaultValue={value.map(val => val.title)}
                    getPopupContainer={node => node.parentNode}
                  >
                    {dropdownObj[key].map(val => (
                      <Option key={val.title} value={val.title}>
                        {val.title}
                      </Option>
                    ))}
                  </Select>
                  {form.getFieldValue(key).map(val => (
                    <Row gutter={24} key={val.title}>
                      <Col span={8}>
                        <Input defaultValue={val.title} />
                      </Col>
                      <Col span={16}>
                        <Input.TextArea
                          rows={2}
                          defaultValue={val.description}
                          onChange={e =>
                            handleCustomFieldDescriptionChange(
                              val.title,
                              e.target.value
                            )
                          }
                          maxLength={300}
                        />
                      </Col>
                    </Row>
                  ))}
                </>
              );
              break;

            case 'twitterAccounts':
            case 'resources':
              inputElement = form.getFieldDecorator(`${key}`, {
                rules: [
                  {
                    required: isColumnDataRequired(key),
                    message: `${key} is required`,
                  },
                  {
                    validator: validateURLs,
                  },
                ],
                initialValue: JSON.stringify(value),
              })(
                <>
                  {(currentValue.length > 0 ? currentValue : ['']).map(
                    (val, idx) => (
                      <>
                        <Input
                          value={val}
                          onChange={e => handleCustomInput(e, idx)}
                          name={key}
                          disabled={!isEditing}
                        />
                      </>
                    )
                  )}
                  <Tooltip title="add">
                    <Button
                      type="primary"
                      shape="circle"
                      icon="plus"
                      onClick={e => onAddClick(e.target.value)}
                      value={key}
                      disabled={!isEditing}
                    />
                  </Tooltip>
                </>
              );
              break;

            case 'tags':
              if (isEmpty(parseString(currentValue))) return null;
              inputElement = form.getFieldDecorator(`${key}`, {
                rules: [
                  {
                    required: isColumnDataRequired(key),
                    message: `${key} is required`,
                  },
                ],
                initialValue: JSON.stringify(value),
              })(
                <>
                  {map(
                    parseString(currentValue?.length > 0 ? currentValue : []),
                    (item, index) => (
                      <Tag
                        closable
                        visible
                        key={item.id}
                        onClose={() => handleTagClick(key, index)}
                      >
                        {item.word}
                      </Tag>
                    )
                  )}
                </>
              );
              break;
            case 'occupations':
              inputElement = (
                <Select
                  showSearch
                  mode="multiple"
                  defaultValue={value}
                  placeholder="Select a Occupation Titles to link"
                  disabled={!isEditing}
                  onChange={handleOccupationFieldChange}
                  getPopupContainer={node => node.parentNode}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dropdownObj[key].map(val => (
                    <Option
                      key={val.id}
                      value={val.id}
                      disabled={
                        val.careerPathId && val.careerPathId !== careerPathId
                      }
                    >
                      {val.title}
                    </Option>
                  ))}
                </Select>
              );
              break;
            default:
          }

          const inputLabel = getInputLabel(key);
          if (key === 'highDemand') inputElement = <div>{inputElement}</div>;
          if (changedFields && changedFieldNames.includes(key)) {
            inputElement = (
              <div className="changelog-input">
                {inputElement || defaultInputElement}
              </div>
            );
          }
          if (key === 'skillsRequired') {
            return inputElement;
          }
          if (key === 'abilities') {
            return null;
          }
          return (
            <Form.Item key={key} label={inputLabel || defaultInputLabel}>
              {inputElement || defaultInputElement}
            </Form.Item>
          );
        }
      );

      // create elements for standardData
      const { standardData = {} } = auxiliaryDataForCareerPaths;
      const standardDataOptionElements = Object.entries(standardData).reduce(
        (pV, [dataSetId, sdata]) => {
          const { options = {}, slug } = sdata;
          const defaultOptions = standardDataRelationships[dataSetId];
          const selectedOptions = [];
          for (let i = 0; i < 5; i += 1) {
            const val = form.getFieldValue(`${slug}-option-${i}`);
            if (val) selectedOptions.push(val.label);
            else if (defaultOptions) {
              const defaultOption = defaultOptions[i] || null;
              if (defaultOption) {
                const defaultOptionId = defaultOption.option;
                const defaultOptionName = options[defaultOptionId];
                selectedOptions.push(defaultOptionName);
              }
            }
          }
          const optionsToShow = Object.entries(options).filter(
            ([optionId, optionName]) => !selectedOptions.includes(optionName)
          );
          return { ...pV, [dataSetId]: optionsToShow };
        },
        {}
      );
      const changedSROptions = {};
      if (changedFieldNames.includes('standardDataRelationships')) {
        for (const field of changedFields) {
          const arr = field.path.split('/');
          if (arr[1] === 'standardDataRelationships') {
            if (arr[2] in changedSROptions)
              changedSROptions[arr[2]] = [...changedSROptions[arr[2]], arr[3]];
            else changedSROptions[arr[2]] = [arr[3]];
          }
        }
      }
      const deleteStandardData = (i, slug, dataSetId) => {
        const defaultOptions = standardDataRelationships[dataSetId];
        if (defaultOptions && defaultOptions[i]) defaultOptions[i] = null;
        form.setFieldsValue({
          [`${slug}-option-${i}`]: '',
        });
      };

      const formElementsForStandardData = Object.entries(standardData).map(
        ([dataSetId, sdata]) => {
          const { name, options: standardOptions, slug } = sdata;
          const defaultOptions = standardDataRelationships[dataSetId] || [];
          const selectElements = [];
          const numInput = name === 'Areas of Study' ? 5 : 3;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < numInput; i++) {
            const defaultOption = defaultOptions[i] || {};
            const defaultOptionId = defaultOption.option;
            const defaultOptionName = standardOptions[defaultOptionId];
            const highlight = changedSROptions[dataSetId]?.includes(
              i.toString()
            )
              ? 'changelog-input'
              : '';
            const options = standardDataOptionElements[dataSetId];
            selectElements.push(
              <div style={{ display: 'flex' }} key={`${slug}-${i}`}>
                {form.getFieldDecorator(`${slug}-option-${i}`, {
                  initialValue: {
                    key: defaultOptionId,
                    label: defaultOptionName,
                  },
                  rules: [
                    {
                      validator: (_, val, callback) => {
                        try {
                          let filled = false;
                          for (let j = 0; j < numInput; j += 1)
                            if (
                              form.getFieldValue(`${slug}-option-${j}`)?.key
                            ) {
                              filled = true;
                              break;
                            }
                          if (!filled) throw new Error(`${name} is required.`);
                          callback();
                        } catch (e) {
                          callback(e);
                        }
                      },
                    },
                  ],
                })(
                  <Select
                    showSearch
                    labelInValue
                    placeholder="Please select"
                    className={highlight}
                    disabled={!isEditing}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {options.map(([optionId, optionName]) => (
                      <Option key={optionId} value={optionId}>
                        {optionName}
                      </Option>
                    ))}
                  </Select>
                )}
                <Button
                  type="primary"
                  shape="circle"
                  icon="delete"
                  style={{ marginLeft: '5px' }}
                  onClick={e => deleteStandardData(i, slug, dataSetId)}
                  disabled={
                    !isEditing ||
                    !form.getFieldValue(`${slug}-option-${i}`)?.key
                  }
                />
              </div>
            );
          }
          const inputLabel = (
            <span>
              <span className="ant-form-item-required" />
              {name}&nbsp;
              {getTooltipForKey('associations') && (
                <>
                  <Tooltip title={getTooltipForKey('associations')}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </>
              )}
            </span>
          );
          return (
            <Form.Item key={`standard-data-${slug}`} label={inputLabel}>
              {selectElements}
            </Form.Item>
          );
        }
      );

      allElements.push(...formElementsForStandardData);
      const orderWiseElements = sortBy(
        filter(allElements, elem => !!elem),
        elem => indexOf(fieldsOrder, elem.key)
      );

      setFormElements(orderWiseElements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    careerPathWithAssociatedData,
    isLoading,
    auxiliaryDataForCareerPaths,
    standardDataRelationships,
    isEditing,
    changedFields,
  ]);

  const validateCustomFields = () => {
    customFields.forEach(field => {
      let val = form.getFieldValue(field);
      val = parseString(val).filter(i => i);
      form.setFieldsValue({
        [field]: JSON.stringify(val),
      });
    });
  };

  const onClickSave = () => {
    validateCustomFields();
    form.validateFieldsAndScroll(
      null,
      { first: true, force: true, scroll: { alignWithTop: false } },
      (err, values) => {
        if (!err) {
          const valuesToSave = {
            ...values,
            skillsRequired: values.skillsAbilities,
            abilities: [],
          };
          delete valuesToSave.skillsAbilities;
          onSave(valuesToSave);
        } else {
          notify(
            'Error',
            'error',
            `${Object.values(err)[0].errors[0].message}`
          );
        }
      }
    );
  };

  return (
    <div className="career-path-form-container">
      {isLoading.careerPathWithAssociatedData ? (
        <Spin className="career-path-form-loader" size="large" />
      ) : (
        <Form className="career-path-form">
          {formElements}
          <div className="action-button-container">
            <Button
              className="cancel-btn"
              onClick={() => onCancel()}
              disabled={isLoading.addCareerPath || isLoading.updateCareerPath}
            >
              Cancel
            </Button>
            <Button
              className="save-btn"
              type="primary"
              onClick={onClickSave}
              loading={isLoading.addCareerPath || isLoading.updateCareerPath}
              disabled={!isEditing}
            >
              Save
            </Button>
            {changeLogId && revertCareerPathChange && (
              <Button
                type="danger"
                onClick={() => revertCareerPathChange(changeLogId)}
                loading={isLoading.revertCareerPathChange}
              >
                Revert
              </Button>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

const CareerPathFormWapper = Form.create()(CareerPathForm);
export default CareerPathFormWapper;
