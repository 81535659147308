/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Modal, Select, Spin, Switch, Icon } from 'antd';
import { cloneDeep, get, isArray, isEmpty, remove, set, uniq } from 'lodash';

import JsonView from './JsonView';
import {
  PLUS_HUB_DEFAULT_ENABLE_PATHS,
  PLUS_OVERRIDE_MODULES,
} from './constants';

import {
  clearVerificationModalState as clearVerificationModalStateAction,
  handleActionInitVerificationModalVisible as handleActionInitVerificationModalVisibleAction,
  handleActionSetClusterHubForVerification as handleActionSetClusterHubForVerificationAction,
  startStandardMigration as startStandardMigrationAction,
} from '../../actions';

import {
  getStandardEnablementGroups,
  // getStandardDefaultEnablementGroup,
} from '../../../../constants/defaultSettingVariationConstants';
// eslint-disable-next-line import/no-named-as-default
import notify from '../../../../utils/notify';

const linkedModules = {
  advising: 'advisingAnalytics',
};

const verificationStatusTextMap = {
  alreadyExists:
    'Migration for this partner has already been done or is in progress. Please contact Engineering team if the status does not change for a long time.',
  'multi-hub-cluster':
    'As of now this tool only allows migrating clusters with single hub in them.',
  'extra-usertypes': `This hub has extra Standard User Types apart from {{ALLOWED_USER_TYPES}}. Please fix this to proceed with migration.`,
  'usertypes-with-link-tab':
    'One or more user types in the hub has static link type of tab/s enabled in visible tabs. Please fix this to continue with V2 platform migration.',
  'no-config-set': `Please set a base config from 'Manage Base Config' before running any V2 Platform migration`,
  'usertypes-with-extra-network-tab':
    'One or more user types in the hub has more than one network tabs enabled. Please fix this to continue with V2 platform migration.',
  'pending-resource-approval':
    'One or more Resources are Pending for Approval in the Hub. Please Fix this to Continue With Migration.',
  'legacy-sso-enabled':
    'Legacy SSO is Enabled in this Cluster. Please migrate to New SSO Settings Object to Continue With Migration.',
  'no-standard-usertypes':
    'This hub does not have any user type with V2 Standard User Type. Please fix this to continue with migration.',
};

const { Option } = Select;

const VerificationModal = ({
  clearVerificationModalState,
  clusterHubForVerification,
  clusterModules,
  hubModules,
  initVerificationModalVisible,
  setClusterHubForVerification,
  setInitVerificationmodalVisible,
  standardMigrationStarting,
  startStandardMigration,
  verificationDataLoading,
  verificationStatus,
  allowedUserTypes,
}) => {
  const [platformType, setPlatformType] = useState('transitioned-standard');
  const [launchGroup, setLaunchGroup] = useState('non_fpu_v2');
  const [preserveTitles, setPreserveTitles] = useState(true);
  const [isPlus, setIsPlus] = useState(false);
  const prevClusterHub = useRef();
  const launchGroups = getStandardEnablementGroups();

  useEffect(() => {
    if (isPlus) {
      const clusterHubCopy = cloneDeep(clusterHubForVerification);
      prevClusterHub.current = clusterHubCopy;
      setOverrides();
    } else {
      !isEmpty(prevClusterHub.current) &&
        setClusterHubForVerification(prevClusterHub.current);
    }
  }, [isPlus]);

  const handleAfterClose = () => {
    setPlatformType('transitioned-standard');
    setLaunchGroup('non_fpu_v2');
    setPreserveTitles(true);
    setIsPlus(false);
    clearVerificationModalState();
  };

  const setOverrides = () => {
    const clusterHubCopy = cloneDeep(clusterHubForVerification);
    PLUS_HUB_DEFAULT_ENABLE_PATHS.forEach(pOveride => {
      set(clusterHubCopy, pOveride, true);
    });
    const enabledModules = get(
      clusterHubCopy,
      `hub.settings.enabledModules`,
      []
    );
    // PG+ will have infinite groups & groups will be enabled.
    const groupSettings = {
      ...get(clusterHubCopy, 'hub.settings.moduleSettings.groups', {}),
      enabled: true,
      numberOfGroupsAdminCanCreate: -1,
    };
    set(
      clusterHubCopy,
      `hub.settings.enabledModules`,
      uniq([...enabledModules, ...PLUS_OVERRIDE_MODULES])
    );
    set(clusterHubCopy, 'hub.settings.moduleSettings.groups', groupSettings);
    set(
      clusterHubCopy,
      'hub.settings.moduleSettings.brandingSettings.enablePGPlusVersion',
      true
    );
    setClusterHubForVerification(clusterHubCopy);
  };

  // const isPGPlus = type =>
  //   ['standard-plus', 'standard-plus-fpu'].includes(type);

  // const handlePlatformTypeChange = value => {
  //   setPlatformType(value);
  //   isPGPlus(value) ? setIsPlus(true) : setIsPlus(false);
  //   const defaultLg = getStandardDefaultEnablementGroup(value);
  //   setLaunchGroup(defaultLg);
  // };

  const handleLaunchGroupChange = value => {
    setLaunchGroup(value);
  };

  // const handleJsonEdit = (updatedSrc, nameSpace) => {
  //   if (nameSpace && nameSpace instanceof Array && nameSpace.length) {
  //     const clusterHubCopy = cloneDeep(clusterHubForVerification);
  //     set(clusterHubCopy, 'hub.settings', get(updatedSrc, 'hubSettings', {}));
  //     set(
  //       clusterHubCopy,
  //       'cluster.settings',
  //       get(updatedSrc, 'clusterSettings', {})
  //     );
  //     setClusterHubForVerification(clusterHubCopy);
  //   }
  // };

  const handleModuleSwitch = (
    checked,
    moduleKey,
    type,
    moduleDetails,
    clusterHubDetails
  ) => {
    const clusterHubCopy =
      clusterHubDetails || cloneDeep(clusterHubForVerification);
    if (moduleDetails.key) {
      const enabledModules = get(
        clusterHubCopy,
        `${type}.settings.enabledModules`,
        []
      );
      if (checked) enabledModules.push(moduleDetails.key);
      else remove(enabledModules, el => el === moduleDetails.key);
      set(
        clusterHubCopy,
        `${type}.settings.enabledModules`,
        uniq(enabledModules)
      );
    }
    set(clusterHubCopy, `${type}.settings.${moduleDetails.path}`, checked);
    return clusterHubCopy;
  };

  const handleSwitchChange = (checked, moduleKey, type, moduleDetails) => {
    // if (SUB_SETTINGS_KEYS.includes(subKey)) {
    //   const keysToUpdate = [`${moduleKey}.${subKey}`];
    //   const clusterHubCopy = cloneDeep(clusterHubForVerification);
    //   keysToUpdate.forEach(key => {
    //     set(clusterHubCopy, `${type}.settings.moduleSettings.${key}`, checked);
    //   });
    //   setClusterHubForVerification(clusterHubCopy);
    // } else
    if (linkedModules[moduleKey]) {
      const keysToUpdate = [moduleKey, linkedModules[moduleKey]];
      const clusterHubCopy = cloneDeep(clusterHubForVerification);
      let enabledModules = get(
        clusterHubCopy,
        `${type}.settings.enabledModules`,
        []
      );
      keysToUpdate.forEach(key => {
        if (checked) enabledModules.push(key);
        else enabledModules = enabledModules.filter(el => el !== key);
        set(
          clusterHubCopy,
          `${type}.settings.moduleSettings.${key}.enabled`,
          checked
        );
      });
      set(
        clusterHubCopy,
        `${type}.settings.enabledModules`,
        uniq(enabledModules)
      );
      setClusterHubForVerification(clusterHubCopy);
    } else {
      // Check if module key has any parent module linked
      const modules = type === 'hub' ? hubModules : clusterModules;
      const parentModuleKey = Object.keys(modules).find(
        modKey => modules[modKey].linkedSubModule === moduleKey
      );
      if (parentModuleKey) {
        // check if parent module is enabled
        const isEnabled = get(
          clusterHubForVerification,
          `${type}.settings.${modules[parentModuleKey].path}`
        );
        if (!isEnabled) {
          notify(`${parentModuleKey} module is not enabled`, 'error');
          return;
        }
      }
      const clusterHubCopy = handleModuleSwitch(
        checked,
        moduleKey,
        type,
        moduleDetails
      );
      if (moduleDetails.linkedSubModule) {
        const subModuleDetails = get(
          type === 'hub' ? hubModules : clusterModules,
          moduleDetails.linkedSubModule
        );
        if (subModuleDetails) {
          const updatedClusterHubCopy = handleModuleSwitch(
            checked,
            moduleDetails.linkedSubModule,
            type,
            subModuleDetails,
            clusterHubCopy
          );
          setClusterHubForVerification(updatedClusterHubCopy);
          return;
        }
      }
      setClusterHubForVerification(clusterHubCopy);
    }
  };

  const handleSaveClick = () => {
    startStandardMigration({
      preserveTitles,
      platformType,
      launchGroup,
      hubId: get(clusterHubForVerification, 'hub.id'),
      clusterId: get(clusterHubForVerification, 'cluster.id'),
      settings: {
        hubSettings: { ...get(clusterHubForVerification, 'hub.settings', {}) },
        clusterSettings: {
          ...get(clusterHubForVerification, 'cluster.settings', {}),
        },
      },
    });
  };

  const renderFooter = () => {
    if (verificationStatus === 'valid') {
      return (
        <div>
          <Button
            onClick={() => setInitVerificationmodalVisible(false)}
            disabled={standardMigrationStarting}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleSaveClick}
            loading={standardMigrationStarting}
          >
            Save &amp; Continue Migration
          </Button>
        </div>
      );
    }

    return (
      <div>
        <Button onClick={() => setInitVerificationmodalVisible(false)}>
          Close
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title="Migrate to V2 Platform"
      className="standard-hub--verification-modal"
      visible={initVerificationModalVisible}
      onCancel={() => setInitVerificationmodalVisible(false)}
      footer={renderFooter()}
      maskClosable={false}
      afterClose={() => handleAfterClose()}
      centered
      destroyOnClose
    >
      <Spin spinning={verificationDataLoading}>
        <div className="standard-hub--verification-modal--body">
          {verificationStatus !== 'valid' &&
          isArray(verificationStatus) &&
          !isEmpty(verificationStatus) ? (
            <div className="error-state">
              <img
                src="https://cdn.peoplegrove.com/admin/1615289299836pg-info-circle-o.svg"
                alt="info-icon"
              />
              <ul>
                {verificationStatus.map(status => (
                  <li>
                    <p className="error-state__heading">
                      {verificationStatusTextMap[status].replace(
                        '{{ALLOWED_USER_TYPES}}',
                        allowedUserTypes.reduce((str, ut, index) => {
                          if (allowedUserTypes.length - 1 === index) {
                            // eslint-disable-next-line no-param-reassign
                            str += `'${ut}'`;
                          } else if (allowedUserTypes.length - 2 === index) {
                            // eslint-disable-next-line no-param-reassign
                            str += `'${ut}' and `;
                          } else {
                            // eslint-disable-next-line no-param-reassign
                            str += `'${ut}', `;
                          }
                          return str;
                        }, '')
                      )}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="valid-state">
              <div className="valid-state--heading">
                <b>V2 Migration for: </b>
                {get(clusterHubForVerification, 'hub.name')}
                (Hub ID: {get(clusterHubForVerification, 'hub.id')} Cluster ID:{' '}
                {get(clusterHubForVerification, 'cluster.id')})
              </div>
              <Divider />
              <div className="valid-state--selections">
                {/* <div className="valid-state--selections__left">
                  <div className="valid-state--select__label">
                    Select Migration Type
                  </div>
                  <Select
                    placeholder="Select Migration Type"
                    className="valid-state--select"
                    value={platformType}
                    onChange={handlePlatformTypeChange}
                    disabled={standardMigrationStarting}
                  >
                    <Option value="standard">V2 | PG Essentials | New</Option>
                    <Option value="transitioned-standard">
                      V2 | PG Essentials | Upgraded
                    </Option>
                    <Option value="standard-plus">V2 | PG + | New</Option>
                    <Option value="standard-plus-fpu">
                      V2 | PG + | Upgraded
                    </Option>
                  </Select>
                </div> */}
                <div className="valid-state--selections__left">
                  <div className="valid-state--select__label">
                    Select Enablement Group
                  </div>
                  <Select
                    placeholder="Select Enablement Group"
                    className="valid-state--select"
                    value={launchGroup}
                    onChange={handleLaunchGroupChange}
                    disabled={standardMigrationStarting}
                  >
                    {launchGroups.map(lg => (
                      <Option value={lg.key} key={lg.key}>
                        {lg.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="valid-state--switch">
                <span className="valid-state--switch__label">
                  Preserve Custom User Type Names
                </span>
                <Switch
                  checked={preserveTitles}
                  onChange={checked => setPreserveTitles(checked)}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                />
              </div>
              <Divider />
              <JsonView
                hubModules={hubModules}
                clusterModules={clusterModules}
                clusterHub={clusterHubForVerification}
                handleSwitchChange={handleSwitchChange}
                // handleJsonEdit={handleJsonEdit}
                linkedModules={linkedModules}
              />
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

const mapStateToProps = ({
  standardHubManagement: {
    clusterHubForVerification,
    clusterModules,
    hubModules,
    initVerificationModalVisible,
    standardMigrationStarting,
    verificationDataLoading,
    verificationStatus,
    allowedUserTypes,
  },
}) => ({
  clusterHubForVerification,
  clusterModules,
  hubModules,
  initVerificationModalVisible,
  standardMigrationStarting,
  verificationDataLoading,
  verificationStatus,
  allowedUserTypes,
});

const mapDispatchToProps = {
  clearVerificationModalState: clearVerificationModalStateAction,
  setInitVerificationmodalVisible:
    handleActionInitVerificationModalVisibleAction,
  setClusterHubForVerification: handleActionSetClusterHubForVerificationAction,
  startStandardMigration: startStandardMigrationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationModal);
