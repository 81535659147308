import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { requestSwitchToken } from "../../api/switchContext.js";
import {
  SET_TOKEN,
  SET_CONTEXT,
  SET_PARTIAL_CONTEXT,
  GET_TOKEN
} from "./actionsSwitchContext";
import { POPUP_ALERT } from "../../actions/alert";
import { setItem, getItem, removeItem } from "../../utils/localStorageService.js";
import { DEFAULTS } from "../../utils/getDefaults.js";
import { getAdminDetails } from "../../reducers/admin.js";
import { getContext } from "./reducerSwitchContext";

const baseUrlMap = {
  LOCAL: "https://www.localhost.com:1337",
  DEV_US: "https://admin.dev.pg-test.com/api",
  PROD_US: "https://admin.peoplegrove.com/api",
  PROD_AU: "https://admin.peoplegrove.com/api-au",
  PROD_EU: "https://admin.peoplegrove.com/api-eu",
  reviewapp: value => `http://admin.${value}.pg-reviewapp.com/api`
};

const getUrl = ({ type, subType }) => {
  if (typeof baseUrlMap[type] === "function") {
    return baseUrlMap[type](subType);
  }
  return baseUrlMap[type];
};

export const requestTokenSaga = function*(params) {
  try {
    let token;
    const { type, subType } = params.data.context;
    console.log('tuype and subTYpe is ---', type, subType);
    const baseURL = getUrl({ type, subType });
    const tokenKey =
      subType && DEFAULTS.requireSubTypes.includes(type)
        ? `${type}${subType}Token`
        : `${type}Token`;
    const existingToken = getItem(tokenKey);
    if (!existingToken) {
      ({ data: token } = yield call(requestSwitchToken, params.data));
      yield put({
        type: SET_TOKEN,
        data: { token: { [tokenKey]: token } }
      });
      yield put({ type: SET_PARTIAL_CONTEXT, data: { baseURL } });
      setItem(tokenKey, token);
    } else {
      token = existingToken;
    }
    setItem([
      ["token", token],
      ["contextBaseURL", baseURL],
      ["contextType", type],
      ["contextSubType", subType]
    ]);
    window.location.reload();
  } catch (err) {
    yield put({ type: POPUP_ALERT });
  }
};

export const setContextSaga = function*(params) {
  try {
    yield put({ type: SET_PARTIAL_CONTEXT, data: params.data });
    const { type, subType = false } = params.data;
    if (!DEFAULTS.requireSubTypes.includes(type)) {

    }
    if (!DEFAULTS.requireSubTypes.includes(type) || subType) {
      const [context, forUser] = yield all([
        select(getContext),
        select(getAdminDetails, ["firstName", "lastName"])
      ]);
      delete context.baseUrl;
      if (!DEFAULTS.requireSubTypes.includes(context.type)) {
        delete context.subType;
        removeItem('contextSubType');
      }
      yield put({ type: GET_TOKEN, data: { forUser, context } });
    }
  } catch (err) {
    yield put({ type: POPUP_ALERT });
  }
};

export default [
  takeLatest(GET_TOKEN, requestTokenSaga),
  takeLatest(SET_CONTEXT, setContextSaga)
];
