import {
  SET_TOKEN,
  SET_CONTEXT,
  SET_PARTIAL_CONTEXT
} from './actionsSwitchContext';
import { getItem } from '../../utils/localStorageService';
import { DEFAULTS } from '../../utils/getDefaults.js';

export const INITIAL_STATE = {
  context: {
    type: getItem('contextType') || DEFAULTS.contextType,
    subType: getItem('contextSubType') || DEFAULTS.contextSubType,
    baseURL: getItem('contextBaseURL') || DEFAULTS.baseURL
  }
};

const switchContext = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case SET_CONTEXT:
    case SET_PARTIAL_CONTEXT:
      return { ...state, context: { ...state.context, ...data } };
    case SET_TOKEN:
      return { ...state, ...data };
    default:
      return { ...state };
  }
};

export const getContext = ({ switch: switchContext }) => ({
  ...switchContext.context
});

export default switchContext;
