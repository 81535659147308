import React from 'react';
import { Select, Row, Col } from 'antd';
import s from '../AnalyticsReport.module.scss';

const { Option } = Select;

class AddActions extends React.Component {
  render() {
    const { onUpdate, actions } = this.props
    return (
      <React.Fragment>
        <Row className={`${s['ant-row']}`}>
          <Row className={`${s['ant-row']}`}>
            <Col span={7}>
              <h3>Add Actions</h3>
            </Col>
            <Col span={7}>
              <Select
                mode="multiple"
                placeholder="Add Types of Actions"
                onChange={onUpdate}
                value={actions}
                style={{ width: 300 }}
                dropdownMatchSelectWidth={false}
              >
                <Option value={'edit'}>Edit Action</Option>
                <Option value={'addToHub'}>Add To Hub</Option>
                <Option value={'permanentDelete'}>Permanent Delete</Option>
              </Select>
            </Col>
          </Row>
        </Row>
      </React.Fragment>
    )
  }
}

export default AddActions;
