import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setContext, getToken } from './actionsSwitchContext';
import { getReviewApps } from '../../api/switchContext';
import SwitchContext from './SwitchContext';
import { DEFAULTS } from "../../utils/getDefaults.js";


export class SwitchContextContainer extends React.Component {
  state = {
    contextTypes: ['PROD_US', 'DEV_US', 'PROD_AU', 'PROD_EU', 'reviewapp'],
    contextSubTypes: [],
    subTypeMethods: {
      reviewapp: () => this.setReviewApps()
    }
  };
  async componentDidMount() {
    const { contextTypes } = this.state;
    const { type } = this.props.context;
    if (window.location.hostname.includes('localhost')) {
      contextTypes.push('LOCAL');
      this.setState({ contextTypes });
    }
    if (DEFAULTS.requireSubTypes.includes(type)) {
      await this.state.subTypeMethods[type]();
    }
  }
  setReviewApps = async () => {
    const {
      data: { reviewApps }
    } = await getReviewApps();
    this.setState({
      contextSubTypes: reviewApps
    });
  };
  handleChange = async (target, value) => {
    await this.props.setContext({ [target]: value });
    if (DEFAULTS.requireSubTypes.includes(value)) {
      await this.state.subTypeMethods[value]();
    }
  };

  render() {
    const { contextTypes, contextSubTypes } = this.state;
    const { context } = this.props;
    return (
      <SwitchContext
        contextTypes={contextTypes}
        contextSubTypes={contextSubTypes}
        context={context}
        handleChange={this.handleChange}
      />
    );
  }
}

const mapDispatchToProps = {
  getToken,
  setContext
};

const mapStateToProps = ({
  switch: { context },
  admin: { firstName, lastName }
}) => ({
  context,
  firstName,
  lastName
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SwitchContextContainer)
);
