import React from 'react';
import { Switch } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Table } from 'antd';
import { fetchAnalyticsReportList, fetchAnalyticsReport } from './actions';
import FullPageLoading from '../../component/FullPageLoading';
// import s from './Analytics.module.scss';
import s from './AnalyticsReport.module.scss';

class AnalyticsReportList extends React.Component {
  componentDidMount() {
    const { fetchAnalyticsReportList } = this.props;
    fetchAnalyticsReportList();
  }

  columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Is Active',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (value, record) => {
        if (!value) {
          return <span style={{ color: 'red' }}>No</span>;
        }
        return <span style={{ color: 'green' }}>Yes</span>;
      },
    },
    {
      title: 'Is Default',
      dataIndex: 'is_default',
      key: 'is_default',
      align: 'center',
      render: (value, record) => {
        if (!value) {
          return <span style={{ color: 'red' }}>No</span>;
        }
        return <span style={{ color: 'green' }}>Yes</span>;
      },
    },
    {
      title: 'Database',
      dataIndex: 'database',
      key: 'database',
      align: 'center',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
  ];

  onToggleQuery = obj => (c, e) => {
    e && e.stopPropagation();
    const { updateAnalyticsQuery } = this.props;
    updateAnalyticsQuery({
      id: obj.id,
      isActive: !obj.isActive,
    });
  };

  handleCreate = () => {
    const { history, clearQueryForm } = this.props;
    // clearQueryForm();
    history.push('/analytics-reports/new');
  };

  onRowClick = record => {
    const { history, fetchAnalyticsReport } = this.props;
    // fetchAnalyticsReport(record.id);
    history.push(`/analytics-reports/edit/${record.id}`);
  };

  render() {
    const { reportList = {} } = this.props;
    const { loading, reports } = reportList;
    return (
      <div className={`ck-card ${s['anaytics-query-table']}`}>
        <Row type="flex" justify="space-between">
          <h3>Analytics Reports</h3>
          <Button type="primary" onClick={this.handleCreate}>
            Create
          </Button>
        </Row>
        <div>
          {loading && <FullPageLoading fontSize={30} />}
          {!loading && (
            <Table
              dataSource={reports}
              columns={this.columns}
              rowKey={record => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => this.onRowClick(record, rowIndex),
                };
              }}
              className={`${s['query-table']}`}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ analyticsReport }) => {
  const { reportList } = analyticsReport;
  return {
    reportList,
  };
};

const mapDispatchToProps = {
  fetchAnalyticsReportList,
  fetchAnalyticsReport,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AnalyticsReportList)
);
