import React from "react";
import { Select } from "antd";
import { DEFAULTS } from "../../utils/getDefaults";

const { Option } = Select;

const SwitchContext = props => (
  <div className="container">
    <Select
      defaultValue={props.context.type}
      onChange={value => props.handleChange("type", value)}
    >
      {props.contextTypes.map((el, i) => (
        <Option value={el} key={`${el}_${i}`}>
          {el}
        </Option>
      ))}
    </Select>
    {DEFAULTS.requireSubTypes.includes(props.context.type) ? (
      <Select
        defaultValue={props.context.subType || ""}
        onChange={value => props.handleChange("subType", value)}
      >
        <Option value="">Select</Option>
        {props.contextSubTypes.map((el, i) => (
          <Option value={el} key={`${el}_${i}`}>
            {el}
          </Option>
        ))}
      </Select>
    ) : null}
  </div>
);

export default SwitchContext;
