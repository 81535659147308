import React, { useState } from 'react';
import { Layout, Menu, Button, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { accessGroupsVerified } from '../../utils/helpers';
import { signOutUser as signOutUserAction } from '../../actions/auth';

import MenuData from './MenuData';
import s from './Layout.module.scss';
import SwitchContextContainer from '../SwitchContext';

const { Header, Content, Sider } = Layout;

const getSelectedKey = location => {
  if (location && location.pathname && location.pathname !== '/') {
    return location.pathname.split('/')[1];
  }

  return 'home';
};

const LayoutWrapper = ({ location, admin, children, signOutUser }) => {
  const [collapsed, setCollapsed] = useState(0);

  const handleCollapse = collapse => {
    setCollapsed(collapse);
  };

  return (
    <Layout
      className={cx(s.layout_parent, {
        [s.layout_parent_collapsed]: collapsed,
      })}
    >
      <Sider
        collapsible
        width={250}
        className={s.layout__sidebar}
        breakpoint="lg"
        onCollapse={handleCollapse}
      >
        <div className={s.logo}>
          <img
            className="full-logo"
            src="https://cdn.peoplegrove.com/admin/1664373169304pg_logo_superadmin_300x81_white.png"
            alt="Peoplegrove logo"
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[getSelectedKey(location)]}
          defaultSelectedKeys={[getSelectedKey(location)]}
          className={s.layout__menu}
        >
          {MenuData.map(menu => {
            if (!menu.subMenu) {
              const verified = accessGroupsVerified(admin, menu.accessGroups);
              if (!verified) {
                return null;
              }
              return (
                <Menu.Item key={menu.key}>
                  <Link to={menu.to}>
                    {menu.icon && <Icon type={menu.icon} />}
                    <span className="nav-text">{menu.title}</span>
                  </Link>
                </Menu.Item>
              );
            }
            return (
              <Menu.SubMenu
                key={menu.key}
                title={
                  <div>
                    {menu.icon && <Icon type={menu.icon} />}
                    <span>{menu.title}</span>
                  </div>
                }
              >
                {menu.subMenu
                  .map(subMenu => {
                    const verified = accessGroupsVerified(
                      admin,
                      subMenu.accessGroups
                    );
                    if (!verified) {
                      return null;
                    }
                    if (subMenu.isInternal) {
                      return (
                        <Menu.Item key={subMenu.key}>
                          <Link to={subMenu.to}>
                            <span className="nav-text">
                              {subMenu.icon && <Icon type={subMenu.icon} />}
                              {subMenu.title}
                            </span>
                          </Link>
                        </Menu.Item>
                      );
                    }
                    return (
                      <Menu.Item key={subMenu.key}>
                        <a href={subMenu.to} target="_blank" rel="noreferrer">
                          <span className="nav-text">{subMenu.title}</span>
                        </a>
                      </Menu.Item>
                    );
                  })
                  .filter(k => !!k)}
              </Menu.SubMenu>
            );
          })}
        </Menu>
      </Sider>
      <Layout>
        <Header className={s.layout_header}>
          <h2>Super Admin</h2>
            <SwitchContextContainer />
          <div>
            <h3>Hello {`${admin.firstName} ${admin.lastName}`}</h3>
            <Button onClick={signOutUser}>Sign Out</Button>
          </div>
        </Header>
        <Content className={s.layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ admin }) => ({
  admin,
});

const mapDispatchToProps = dispatch => ({
  signOutUser: count => dispatch(signOutUserAction(count)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutWrapper)
);
