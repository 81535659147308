import React from 'react';
import { Table, Button } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import './process-alert-service.scss';

const getStatusColor = status => {
  let color = 'rgba(0, 0, 0, 0.65)';
  switch (status) {
    case 'success':
      color = '#36b37e'; // green
      break;
    case 'failed':
    case 'timeout':
    case 'partial failed':
      color = '#ff5630'; // red
      break;
    case 'waiting':
      color = '#ff991f'; // yellow
      break;
    case 'running':
      color = '#00b8d9'; // blue
      break;
    default:
      return color;
  }
  return color;
};

const showEmailStatus = row => {
  if (
    row.status === 'success' ||
    row.status === 'partial failed' ||
    row.emailSent === false
  ) {
    return false;
  }
  return true;
};

const getEmailStatus = status => {
  switch (status) {
    case null:
      return 'To be processed';
    case false:
      return 'Not required';
    case true:
      return 'Processed';
    default:
      return 'Error';
  }
};

const getInfo = row => {
  const isSubmittedRecords = !isNaN(parseInt(row.submittedRecords, 10));
  if (isSubmittedRecords) {
    if (
      ['exportSync', 'analytics', 'handshake'].includes(row.type) &&
      row.status === 'success'
    ) {
      return `${row.submittedRecords}/${row.submittedRecords}`;
    }
    return `${row.successRecords || 0}/${row.submittedRecords}`;
  }
  return 'NA';
};

const ProcessAlertService = ({
  processHistories,
  count,
  loading,
  onChange,
  page,
  hubUrls = {},
  openHub,
}) => {
  if (!loading && isEmpty(processHistories)) {
    return <div>No Process History found.</div>;
  }

  const getUrl = row => {
    const baseUrl = hubUrls[row.hub]?.url;
    switch (row.type) {
      case 'import':
        return `${baseUrl}/site-admin/initial-setup/import-data/${row.processId}`;
      case 'dataSync':
        return `${baseUrl}/site-admin/initial-setup/data-sync/${row.processId}`;
      case 'exportSync':
        return `${baseUrl}/site-admin/initial-setup/export-data/${row.processId}`;
      case 'salesforce':
        return `${baseUrl}/site-admin/integrations/salesforce/settings/${row.processId}/history/`;
      case 'handshake':
        return `${baseUrl}/site-admin/integrations/handshake/settings/`;
      case 'wayup':
        return `${baseUrl}/site-admin/integrations/wayup/monitoring/`;
      case 'symplicity':
        return `${baseUrl}/site-admin/integrations/symplicity/monitoring/`;
      case 'slate':
        return `${baseUrl}/site-admin/integrations/slate-sync/settings/${row.processId}/history/`;
      case 'nxt':
        return `${baseUrl}/site-admin/integrations/raisers-edge-nxt/monitoring/`;
      case 'analytics':
        return `/build-analytics-chart`;
      default:
        return baseUrl;
    }
  };
  const momentFormat = 'lll';
  const pageSize = 10;
  const columns = [
    {
      title: 'ID',
      dataIndex: 'processId',
      key: 'processId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (t, r) => (
        <a
          href={getUrl(r)}
          title={`Go to ${t} page`}
          target="_blank"
          rel="noreferrer"
        >
          {t}
        </a>
      ),
    },
    {
      title: 'History ID',
      dataIndex: 'historyId',
      key: 'historyId',
    },
    {
      title: 'Process Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (t, r) => (
        <div>
          <span style={{ color: getStatusColor(r.status), fontWeight: 'bold' }}>
            {r.status}
          </span>
        </div>
      ),
    },
    {
      title: 'Success / Submitted',
      dataIndex: 'info',
      key: 'info',
      render: (t, r) => <div>{getInfo(r)}</div>,
    },
    {
      title: 'Email Status',
      dataIndex: 'emailSent',
      key: 'emailSent',
      render: (t, r) => (
        <div>{showEmailStatus(r) ? `${getEmailStatus(t)}` : 'NA'}</div>
      ),
    },
    {
      title: 'Cluster',
      dataIndex: 'cluster',
      key: 'cluster',
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (t, r) => (
        <div>
          {r.startTime ? (
            <div>{moment(r.startTime).format(momentFormat)}</div>
          ) : null}
        </div>
      ),
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (t, r) => (
        <div>
          {r.endTime ? (
            <div>{moment(r.endTime).format(momentFormat)}</div>
          ) : null}
        </div>
      ),
    },
    {
      title: 'Hub',
      dataIndex: 'hub',
      key: 'hub',
      fixed: 'right',
      render: (t, r) => (
        <div>
          {t ? (
            <Button
              type="link"
              onClick={() =>
                openHub({
                  hub: r.hub,
                  reason: `Process Alert Service: Checking ${r.type} process for the hub`,
                })
              }
              title="Open Hub"
            >
              {hubUrls[t]?.name} - {t}
            </Button>
          ) : null}
        </div>
      ),
    },
  ];
  return (
    <div className="process-alert-service-wrapper">
      <Table
        className="process-alert-service-wrapper__table"
        scroll={{
          x: 'max-content',
        }}
        loading={loading}
        columns={columns}
        dataSource={processHistories.map(ph => ({ ...ph, key: ph.id }))}
        pagination={{
          simple: false,
          total: Math.floor(count / pageSize) * pageSize,
          pageSize,
          current: page,
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default ProcessAlertService;
