const HOSTNAME = window.location.hostname;
let defaultBaseUrl, defaultContextType;
const REQUIRE_SUB_TYPES = ['reviewapp'];

switch (HOSTNAME) {
  case 'admin.prod.static.pg.services':
    defaultContextType = 'PROD_US';
    defaultBaseUrl = 'https://api.peoplegrove.com';
    break;
  case 'admin.beta.static.pg.services':
    defaultContextType = 'DEV_US';
    defaultBaseUrl = 'https://admin.dev.pg-test.com/api';
    break;
  default:
    defaultContextType = 'LOCAL';
    defaultBaseUrl = 'https://www.localhost.com:1337';
    break;
}

export const DEFAULTS = {
  baseURL: defaultBaseUrl,
  contextType: defaultContextType,
  requireSubTypes: [...REQUIRE_SUB_TYPES],
};
