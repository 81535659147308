import React, { PureComponent } from 'react';
import { List, Button, Card, Modal } from 'antd';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import s from './ClusterHubList.module.scss';

import HubSFAssociationStatus from '../HubSFAssociationStatus/HubSFAssociationStatus';
import {
  setLoginModal as setLoginModalAction,
  setSelectedClusterHub as setSelectedClusterHubAction,
} from '../../actions/clusterHubs';
import ReasonModal from '../../component/ReasonModal/LoginReasonModal';

class ClusterHubList extends PureComponent {
  handleLoginClick = (item, showLoginReasonModal = false) => {
    if (item.isHubCreationPending) {
      Modal.error({
        title: 'Hub Creation Pending',
        content: 'Please wait until hub has been created.',
        // onOk: () => openHub({ clusterHub: item.clusterHub }),
        // okText: 'Log In',
      });
    } else {
      const { setLoginModal, setSelectedClusterHub, handleLogin } = this.props;
      if (!isEmpty(item.sfAccountId) && item.isProduction) {
        setLoginModal(showLoginReasonModal);
        setSelectedClusterHub(item.clusterHub);
      } else {
        handleLogin(item.clusterHub);
      }
    }
  };

  getListItems = () => {
    const listData = [];
    const { data, clusterHubOpening } = this.props;
    data.forEach(ch => {
      const { id, name, domain, hubs } = ch;
      const hubData = hubs.map(h => ({
        clusterHub: h.clusterHubId,
        id: h.id,
        name: h.name,
        subDomain: h.subDomain,
        isHubCreationPending: h.isHubCreationPending,
        sfAccountId: h.sfAccountId,
        isProduction: h.isProduction,
        deleteRequestedAt: h.deleteRequestedAt,
      }));
      const content = (
        <List
          size="small"
          dataSource={hubData}
          itemLayout="vertical"
          bordered
          renderItem={item => (
            <List.Item
              key={item.id}
              extra={
                <Button
                  onClick={() => this.handleLoginClick(item, true)}
                  loading={clusterHubOpening === item.clusterHub}
                  type="primary"
                >
                  Log In
                </Button>
              }
            >
              <List.Item.Meta
                title={
                  <div className={s.salesforce}>
                    <p>{`Hub - ${item.name}`}</p>
                    {this.renderSFRelatedElements(item)}
                  </div>
                }
                description={`(Subdomain - ${item.subDomain}, Hub ID - ${item.id})`}
              />
            </List.Item>
          )}
        />
      );
      listData.push({
        id,
        title: `${name} - ${domain} (Cluster ID - ${id})`,
        content,
      });
    });
    return listData;
  };

  onSubmitReason = reason => {
    const { handleLogin, selectedClusterHub, setLoginModal } = this.props;
    handleLogin(selectedClusterHub, reason);
    setLoginModal(false);
  };

  renderSFRelatedElements = hub => (
    <>
      {hub.sfAccountId && (
        <Button
          size="small"
          type="primary"
          onClick={() =>
            window.open(
              `https://peoplegrove.lightning.force.com/lightning/r/Account/${hub.sfAccountId}/view`,
              '_blank'
            )
          }
        >
          Salesforce
        </Button>
      )}
      <HubSFAssociationStatus hubData={hub} showBreak={!!hub.sfAccountId} />
    </>
  );

  render() {
    const listData = this.getListItems();
    const { loading, isLoginModalOpen, setLoginModal } = this.props;
    return (
      <>
        <ReasonModal
          isOpen={isLoginModalOpen}
          onSubmit={this.onSubmitReason}
          onClose={() => setLoginModal(false)}
          title="Login Reason"
          errorMessage="Please select a reason to login in this prod site"
        />
        <List
          loading={loading}
          itemLayout="vertical"
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            pageSize: 10,
            showTotal: (total, range) =>
              `${range[0]} to ${range[1]} of ${listData.length}`,
          }}
          dataSource={listData}
          renderItem={item => (
            <List.Item key={item.id}>
              <Card type="inner" title={item.title} hoverable>
                {item.content}
              </Card>
            </List.Item>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  clusterHubs: { isLoginModalOpen, selectedClusterHub, clusterHubOpening },
}) => ({
  isLoginModalOpen,
  selectedClusterHub,
  clusterHubOpening,
});

const mapDispatchToProps = {
  setLoginModal: setLoginModalAction,
  setSelectedClusterHub: setSelectedClusterHubAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClusterHubList);
