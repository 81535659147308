export const SAVE_ANALYTICS_QUERY = 'SAVE_ANALYTICS_QUERY';
export const SET_QUERY_LIST = 'SET_QUERY_LIST';
export const FETCH_EXISTING_QUERY = 'FETCH_EXISTING_QUERY';
export const SET_QUERY_LIST_LOADING = 'SET_QUERY_LIST_LOADING';
export const FETCH_QUERY_BASED_ON_ID = 'FETCH_QUERY_BASED_ON_ID';
export const SET_QUERY_IS_CUSTOM = 'SET_QUERY_IS_CUSTOM';
export const SET_QUERY_TITLE = 'SET_QUERY_TITLE';
export const SET_QUERY_CODE = 'SET_QUERY_CODE';
export const SET_IS_QUERY_CODE_CHANGED = 'SET_IS_QUERY_CODE_CHANGED';
export const CLEAR_QUERY_FORM = 'CLEAR_QUERY_FORM';
export const UPDATE_ANALYTICS_QUERY = 'UPDATE_ANALYTICS_QUERY';
export const SET_QUERY_PROCESSING = 'SET_QUERY_PROCESSING';
export const SET_QUERY_FORM_LOADING = 'SET_QUERY_FORM_LOADING';
export const SET_SELECTED_QUERY_HUB = 'SET_SELECTED_QUERY_HUB';
export const SET_QUERY_TABLE_NAME = 'SET_QUERY_TABLE_NAME';
export const SET_QUERY_DB = 'SET_QUERY_DB';
export const SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS';
export const SET_UPDATE_REQUIRED = 'SET_UPDATE_REQUIRED';
export const SET_ANALYTICS_QUERY_ACTIVE = 'SET_ANALYTICS_QUERY_ACTIVE';
export const FETCH_EXISTING_ASSOCIATED_CHART =
  'FETCH_EXISTING_ASSOCIATED_CHART';
export const SET_CHART_LIST = 'SET_CHART_LIST';
export const FETCH_REPORT_TABLES = 'FETCH_REPORT_TABLES';
export const SET_REPORT_TABLE = 'SET_REPORT_TABLE';
export const FETCH_ASSOCIATED_CHART_DATA = 'FETCH_ASSOCIATED_CHART_DATA';
export const SET_ASSOCIATED_REPORT_ID = 'SET_ASSOCIATED_REPORT_ID';
export const SET_ASSOCIATED_CHART_TYPE = 'SET_ASSOCIATED_CHART_TYPE';
export const SET_ASSOCIATED_CHART_QUERY = 'SET_ASSOCIATED_CHART_QUERY';
export const SET_ASSOCIATED_CHART_TAG = 'SET_ASSOCIATED_CHART_TAG';
export const SAVE_ASSOCIATED_CHART = 'SAVE_ASSOCIATED_CHART';
export const UPDATE_ASSOCIATED_CHART = 'UPDATE_ASSOCIATED_CHART';
export const SET_ASSOCIATED_CHART_IDENTIFIER =
  'SET_ASSOCIATED_CHART_IDENTIFIER';
export const SET_INDEX_CODE = 'SET_INDEX_CODE';
export const SET_RELATED_REPORT_ID = 'SET_RELATED_REPORT_ID';
export const SET_CHANGE_MESSAGE = 'SET_CHANGE_MESSAGE';

export const saveAnalyticsQuery = data => ({
  type: 'SAVE_ANALYTICS_QUERY',
  data,
});

export const setQueryList = data => ({
  type: 'SET_QUERY_LIST',
  data,
});

export const fetchExistingQuery = () => ({
  type: 'FETCH_EXISTING_QUERY',
});

export const setQueryListLoading = data => ({
  type: 'SET_QUERY_LIST_LOADING',
  data,
});

export const fetchQueryBasedOnId = data => ({
  type: 'FETCH_QUERY_BASED_ON_ID',
  data,
});

export const setQueryIsCustom = data => ({
  type: 'SET_QUERY_IS_CUSTOM',
  data,
});

export const setQueryTitle = data => ({
  type: 'SET_QUERY_TITLE',
  data,
});

export const setQueryCode = data => ({
  type: 'SET_QUERY_CODE',
  data,
});

export const setIsQueryCodeChanged = data => ({
  type: 'SET_IS_QUERY_CODE_CHANGED',
  data,
});

export const clearQueryForm = () => ({
  type: 'CLEAR_QUERY_FORM',
});

export const updateAnalyticsQuery = data => ({
  type: 'UPDATE_ANALYTICS_QUERY',
  data,
});

export const setQueryProcessing = data => ({
  type: 'SET_QUERY_PROCESSING',
  data,
});

export const setQueryFormLoading = data => ({
  type: 'SET_QUERY_FORM_LOADING',
  data,
});

export const setSelectedQueryHub = data => ({
  type: 'SET_SELECTED_QUERY_HUB',
  data,
});

export const setQueryTableName = data => ({
  type: 'SET_QUERY_TABLE_NAME',
  data,
});

export const setQueryDb = data => ({
  type: 'SET_QUERY_DB',
  data,
});

export const setTableColumns = data => ({
  type: 'SET_TABLE_COLUMNS',
  data,
});

export const setUpdateRequired = data => ({
  type: 'SET_UPDATE_REQUIRED',
  data,
});

export const setAnalyticsQueryActive = data => ({
  type: 'SET_ANALYTICS_QUERY_ACTIVE',
  data,
});

export const fetchExistingAssociatedChart = () => ({
  type: 'FETCH_EXISTING_ASSOCIATED_CHART',
});

export const setChartList = data => ({
  type: 'SET_CHART_LIST',
  data,
});

export const fetchReportTables = () => ({
  type: 'FETCH_REPORT_TABLES',
});

export const fetchAssociatedChartData = data => ({
  type: 'FETCH_ASSOCIATED_CHART_DATA',
  data,
});

export const updateAssociatedChart = data => ({
  type: 'UPDATE_ASSOCIATED_CHART',
  data,
});

export const saveAssociatedChart = data => ({
  type: 'SAVE_ASSOCIATED_CHART',
  data,
});

export const setAssociatedReportId = data => ({
  type: 'SET_ASSOCIATED_REPORT_ID',
  data,
});

export const setAssociatedChartType = data => ({
  type: 'SET_ASSOCIATED_CHART_TYPE',
  data,
});

export const setAssociatedChartTag = data => ({
  type: 'SET_ASSOCIATED_CHART_TAG',
  data,
});

export const setAssociatedChartQuery = data => ({
  type: 'SET_ASSOCIATED_CHART_QUERY',
  data,
});

export const setAssociatedChartIdentifier = data => ({
  type: 'SET_ASSOCIATED_CHART_IDENTIFIER',
  data,
});

export const setIndexCode = data => ({
  type: 'SET_INDEX_CODE',
  data,
});

export const setRelatedReportId = data => ({
  type: 'SET_RELATED_REPORT_ID',
  data,
});

export const setChangeMessage = data => ({
  type: 'SET_CHANGE_MESSAGE',
  data,
});

export const queryList = ({ analyticsQuery }) => analyticsQuery.queryList;
export const chartList = ({ analyticsQuery }) => analyticsQuery.chartList;
